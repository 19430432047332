import * as React from 'react'
import * as C from '../components'
import {firebase} from '../config/firebase.config'
import theme from '../styles/theme'
import {useLocalStorage} from "../hooks";
import API from '../api'

export const AuthContext = React.createContext({});
export const AuthProvider = ({ children }) => {
    const [isLogged, setIsLogged] = React.useState(null);
    const [pending, setPending] = React.useState(true);
    const ls = useLocalStorage()

    const checkAdmin = async (user) => {
        const user_id = user.uid;
        const token = await user.getIdToken()
        const isAdmin = await API.USERS.checkAdmin(user_id, token)
        if(isAdmin.success) {
            await ls.AUTH.setUserId(user_id)
            await ls.AUTH.setUserToken(token, user_id)
            await ls.AUTH.setEmail(user.email)
            return true
        } else {
            return false
        }
    }

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                checkAdmin(user).then((isAdmin) => {
                    if(isAdmin) {
                        setIsLogged(true)
                        setPending(false)
                        return true
                    } else {
                        setIsLogged(false)
                        setPending(false)
                        alert('Non sei un amministratore.')
                        return false
                    }
                })
            } else {
                setPending(false)
                setIsLogged(false)
            }
        })
    }, []);

    if(pending){
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <C.LOADERS.OverlayLoader active={pending} />
            </div>
        )
    }

    return (
        <AuthContext.Provider
            value={{
                isLogged, setIsLogged
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const ThemeContext = React.createContext({})
export const ThemeProvider = ({children}) => {
    return(
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}