import firebase from 'firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBi4-4qmkuAAOCcSzxknkWT2h8Tv0iObBM",
    authDomain: "fantalegends-d9ff4.firebaseapp.com",
    projectId: "fantalegends-d9ff4",
    storageBucket: "fantalegends-d9ff4.appspot.com",
    messagingSenderId: "549360132146",
    appId: "1:549360132146:web:3d9c04a24c78b378941c2b",
    measurementId: "G-9VJ33DY5HR"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export { firebase };