import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import * as C from '../components'
import DashboardContent from './dashboardContent'
import StatsRoutes from "./stats/statsRoutes";
import CardsRoutes from "./cards/cardsRoutes";
import EventsRoutes from "./events/eventsRoutes"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function DashboardPage(props) {
    const { window } = props
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <C.SURFACES.MainBar />
            <C.NAVIGATIONS.MainDrawer window={window} />
            <C.ROUTES.PrivateRoute exact path="/" component={DashboardContent} />
            <C.ROUTES.PrivateRoute path='/stats' component={StatsRoutes} />
            <C.ROUTES.PrivateRoute path='/cards' component={CardsRoutes} />
            <C.ROUTES.PrivateRoute path='/events' component={EventsRoutes} />
        </div>
    );
}
