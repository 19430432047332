import React from "react"
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import css from '../styles/css'
import {useMediaQuery, useTheme} from "@material-ui/core";
import { SearchOutline } from 'react-ionicons'

export const LoginButton = withStyles({
    root: {
        backgroundColor: css.palette.fl1,
        '&:hover': {
            backgroundColor: css.palette.fl2,
            borderColor: css.palette.fl2
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
})(Button);

export const SearchButton = ({width, onClick, height, mt}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const MyButton = withStyles({
        root: {
            backgroundColor: css.palette.fl3,
            verticalAlign: 'bottom',
            height: height || 40,
            width: width || 150,
            marginTop: matches_sm ? 20 : mt || 'inherit',
            color: css.palette.text(.7),
            '&:hover': {
                backgroundColor: css.palette.fl4,
                borderColor: css.palette.fl4,
                color: css.palette.text(.8),
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        }
    })(Button)

    return(
        <MyButton onClick={onClick}><SearchOutline color={'#00000'} title={'SEARCH'} height="25px" width="25px" style={{verticalAlign: 'middle', paddingRight: 10}} /> CERCA</MyButton>
    )
}

export const ActionButton = ({width, onClick, height, IconComponent, label, containerStyle}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const MyButton = withStyles({
        root: {
            border: '1px solid '+css.palette.fl3,
            verticalAlign: 'bottom',
            height: height || 40,
            width: width || 150,
            marginTop: matches_sm ? 20 : 'inherit',
            color: css.palette.text(.7),
            '&:hover': {
                backgroundColor: css.palette.fl4,
                borderColor: css.palette.fl4,
                color: css.palette.text(.8),
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
            ...containerStyle
        }
    })(Button)

    return(
        <MyButton onClick={onClick} variant="outlined">
            {
                IconComponent ? <IconComponent /> : undefined
            } {label.toUpperCase()}
        </MyButton>
    )
}