import axios from "axios";
import {ROOT_ENDPOINT, ROOT_ADMIN_ENDPOINT, send} from '../config/api.config'
import {useLocalStorage} from "../hooks";

const ENDPOINT = ROOT_ENDPOINT + '/cards'
const ADMIN_ENDPOINT = ROOT_ADMIN_ENDPOINT + '/cards'

const User = async () => {
    const ls = useLocalStorage()
    const authToken = await ls.AUTH.getUserToken()
    const userID = ls.AUTH.getUserId()
    return {
        authToken,
        userID
    }
}

export const getCardsByTeamAndSeason = async (team, season) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/cards_by_team_and_season',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    season,
                    team
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const getAllCardsByTeamAndSeason = async (team, season) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/all_cards_by_team_and_season',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    season,
                    team
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const getCardsByTournamentAndPosition = async (tournament, position, algorithm) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/cards_by_tournament_and_position',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    tournament, position, algorithm
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const getFirstCardWithoutPositionOrRarity = async (tournament, team) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/first_player_without_position_or_rarity',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    tournament,
                    team
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const setRarity = async (card, rarity) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/rarity',
            {
                card, rarity
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const setPositions = async (card, positions) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/positions',
            {
                card, positions
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const setAvailability = async (card, active, released) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/availability',
            {
                card, active, released
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const setShirtNumber = async (card, number) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/shirt_number',
            {
                card, number
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getTransfers = async () => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/transfers_table',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const setTransfersFromValidity = async (value, id) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/transfers_from_set_validity',
            {
                validity: value,
                id: id
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const setTransfersToValidity = async (value, id) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/transfers_to_set_validity',
            {
                validity: value,
                id: id
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}