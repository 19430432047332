import * as React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Icon from '@mdi/react'
import { mdiCardPlus, mdiDelete, mdiContentSave } from '@mdi/js'
import { sortBy } from 'lodash'
import moment from 'moment'

import * as C from "components";
import css from 'styles/css'
import API from 'api'
import {useToast} from 'hooks'

export default function StageForm(props) {
    const [stageName, setStageName] = React.useState(null)
    const [stageNumber, setStageNumber] = React.useState()
    const [stageDescription, setStageDescription] = React.useState(null)
    const [stageStart, setStageStart] = React.useState('')
    const [stageEnd, setStageEnd] = React.useState('')
    const [stageFormat, setStageFormat] = React.useState(null)
    const [stageFormatName, setStageFormatName] = React.useState(null)
    const [numberOfRounds, setNumberOfRounds] = React.useState(null)
    const [numberOfGroups, setNumberOfGroups] = React.useState(null)
    const [groupSize, setGroupSize] = React.useState(null)
    const [hasMatchmaking, setHasMatchmaking] = React.useState(null)
    const [mmStart, setMmStart] = React.useState('')
    const [mmEnd, setMmEnd] = React.useState('')
    const [prizes, setPrizes] = React.useState([])
    const [fromRank, setFromRank] = React.useState(null)
    const [toRank, setToRank] = React.useState(null)
    const [prizeQty, setPrizeQty] = React.useState(null)
    const [prizeItem, setPrizeItem] = React.useState(null)
    const [prizeItemName, setPrizeItemName] = React.useState(null)
    const [hasStandings, setHasStandings] = React.useState(null)

    const eventID = props.eventID;

    const useStyles = makeStyles((theme) => ({
        multipleInput: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        fromToRank: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        },
        rankPrizeCat: {
            borderTop: '1px solid '+css.palette.fl4_alpha(.5),
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    }));

    const classes = useStyles()
    const toast = useToast()

    const addRankPrizes = () => {
        if(+toRank < +fromRank) {
            alert("ERRORE: A non può essere maggiore di DA!")
        } else {
            const new_prizes = prizes.slice()
            new_prizes.push(
                {
                    from: fromRank,
                    to: toRank,
                    prizes: []
                }
            )
            setPrizes(new_prizes)
        }
    }
    const removeRankPrizes = (from) => {
        const new_prizes = prizes.filter((i) => i.from !== from)
        setPrizes(new_prizes)
    }
    const addPrizesToRank = (from) => {
        const new_prizes = prizes.slice()
        new_prizes.filter((i) => i.from === from)[0].prizes.push({
            id: prizeItem,
            name: prizeItemName,
            qty: prizeQty
        })
        setPrizes(new_prizes)
    }
    const onSave = async () => {
        const stage = {
            event: eventID,
            name: stageName,
            number: stageNumber,
            description: stageDescription,
            start: moment(stageStart).utc().format().toString(),
            end: moment(stageEnd).utc().format().toString(),
            format: stageFormat,
            hasStandings: hasStandings,
            rounds: numberOfRounds,
            groups: numberOfGroups,
            groupSize: groupSize,
            hasMM: hasMatchmaking,
            mmStart: moment(mmStart).utc().format().toString(),
            mmEnd: moment(mmEnd).utc().format().toString(),
            prizes: prizes
        }
        const res = await API.EVENTS.saveNewStage(stage)
        if(res.success) {
            toast.success('Fase Salvata!')
        } else {
            toast.error(res.error)
        }
    }

    return(
        <Grid style={{display: 'flex', justifyContent: 'space-around'}}>
            <Grid item sm={12} md={4}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <C.INPUTS.Number label="Numero" value={stageNumber} onChange={(e) => setStageNumber(e.target.value)} width="30%" />
                        <C.INPUTS.Text label="Nome" value={stageName} onChange={(e) => setStageName(e.target.value)} width="100%" />
                        <C.INPUTS.Text label="Descrizione" value={stageDescription} onChange={(e) => setStageDescription(e.target.value)} width="100%" multiline rows={4}/>
                        <Grid item sm={12} className={classes.multipleInput}>
                            <C.INPUTS.DateTime label="Inizio" value={stageStart} onChange={(e) => setStageStart(e.target.value)} width="48%" />
                            <C.INPUTS.DateTime label="Fine" value={stageEnd} onChange={(e) => setStageEnd(e.target.value)} width="48%" />
                        </Grid>
                        <C.SELECTS.StageFormats label="Formato" value={stageFormat} onChange={(e) => { setStageFormat(e.target.value); setStageFormatName(e.target.options[e.target.selectedIndex].text) }} width="100%" pt={10} />
                        <C.SELECTS.YesOrNo label="Ha la classifica?" value={hasStandings} onChange={(e) => setHasStandings(e.target.value)} width="100%" pt={20} />
                        <Grid item sm={12} className={classes.multipleInput} style={{marginTop: 5}}>
                            <C.INPUTS.Number label="N° Fasi" value={numberOfRounds} onChange={(e) => setNumberOfRounds(e.target.value)} width="30%" />
                            <C.INPUTS.Number label="N° Gruppi" value={numberOfGroups} onChange={(e) => setNumberOfGroups(e.target.value)} width="30%" />
                            <C.INPUTS.Number label="Grandezza Gruppi" value={groupSize} onChange={(e) => setGroupSize(e.target.value)} width="30%" />
                        </Grid>
                        <Grid item sm={12} className={classes.multipleInput} style={{marginTop: 5}}>
                            <C.SELECTS.YesOrNo label="MM?" value={hasMatchmaking} onChange={(e) => setHasMatchmaking(e.target.value)} width="15%" pt={24} />
                            <C.INPUTS.DateTime label="Inizio MM" value={mmStart} onChange={(e) => setMmStart(e.target.value)} width="40%" />
                            <C.INPUTS.DateTime label="Fine MM" value={mmEnd} onChange={(e) => setMmEnd(e.target.value)} width="40%" />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item sm={12} md={4}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Typography variant="h6">PREMI</Typography>
                        <Grid item sm={12} className={classes.fromToRank} style={{marginTop: 5}}>
                            <C.INPUTS.Number label="Da #" value={fromRank} onChange={(e) => setFromRank(e.target.value)} width="25%" />
                            <C.INPUTS.Number label="A #" value={toRank} onChange={(e) => setToRank(e.target.value)} width="25%" />
                            <C.BUTTONS.ActionButton label="" onClick={addRankPrizes} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="10%" containerStyle={{marginTop: 24}} />
                        </Grid>
                        <Grid item sm={12} style={{marginTop:15}}>
                            {
                                sortBy(prizes, [function(o) { return +o.from; }]).map((i) => {
                                    return(
                                        <Grid item sm={12} className={classes.rankPrizeCat}>
                                            <Grid item sm={2}>
                                                {
                                                    i.from === i.to ?
                                                        <Typography>Al {i.from}°</Typography>
                                                        :
                                                        <Typography>Dal {i.from}° al {i.to}°</Typography>
                                                }
                                            </Grid>
                                            <Grid item sm={9} style={{display: 'flex', flexDirection: 'column'}}>
                                                <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
                                                    <C.INPUTS.Number label="Qty" value={prizeQty} onChange={(e) => setPrizeQty(e.target.value)} width="20%" />
                                                    <C.SELECTS.Prizes
                                                        label="Premio"
                                                        value={prizeItem}
                                                        onChange={(e) => {setPrizeItem(e.target.value); setPrizeItemName(e.target.options[e.target.selectedIndex].text)}}
                                                        pt={17}
                                                        width="40%"
                                                    />
                                                    <C.BUTTONS.ActionButton label="" onClick={() => addPrizesToRank(i.from)} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="20%" />
                                                </Grid>
                                                <Grid item sm={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10}}>
                                                    {
                                                        i.prizes.map((p) => <Typography key={p.id}>{p.qty} {p.name}</Typography>)
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <C.BUTTONS.ActionButton
                                                    label=""
                                                    onClick={() => removeRankPrizes(i.from)}
                                                    IconComponent={() => <Icon path={mdiDelete} style={{height: 20, width: 20}} /> }
                                                    width="100%"
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item sm={12} md={3}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h4">RIEPILOGO</Typography>
                        <Typography variant="h6">NOME: {stageName}</Typography>
                        <Typography variant="h6">DESCRIZIONE: {stageDescription}</Typography>
                        <Typography variant="h6">Inizia il: {stageStart}</Typography>
                        <Typography variant="h6">Finisce il: {stageEnd}</Typography>
                        <Typography variant="h6">Formato: {stageFormatName}</Typography>
                        <Typography variant="h6">Ha la classifica? {+hasStandings === 1 ? 'SI' : 'NO'}</Typography>
                        <Typography variant="h6">Fasi: {numberOfRounds}</Typography>
                        <Typography variant="h6">Gruppi: {numberOfGroups}</Typography>
                        <Typography variant="h6">Utenti per Gruppo: {groupSize}</Typography>
                        <Typography variant="h4" style={{marginTop: 15}}>MATCHMAKING</Typography>
                        <Typography variant="h6">Ha il matchmaking? {+hasMatchmaking === 1 ? 'SI' : 'NO'}</Typography>
                        <Typography variant="h6">Inizio MM: {mmStart}</Typography>
                        <Typography variant="h6">Fine MM: {mmEnd}</Typography>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
                        <C.BUTTONS.ActionButton
                            label="SALVA"
                            onClick={onSave}
                            IconComponent={() => <Icon path={mdiContentSave} style={{height: 20, width: 20}} />}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
