import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";

import * as C from 'components'
import API from 'api'
import {useToast} from "hooks";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    }
}));

export default function AvailabilityMod() {
    const classes = useStyles()
    const toast = useToast()

    const [selectedSeason, setSelectedSeason] = React.useState("")
    const [selectedTournament, setSelectedTournament] = React.useState("")
    const [selectedTeam, setSelectedTeam] = React.useState("")
    const [cards, setCards] = React.useState()
    const [cardsReady, setCardsReady] = React.useState(false)
    const [areCardsLoading, setAreCardsLoading] = React.useState(false)

    const onChangeSeason = (event) => {
        setSelectedSeason(event.target.value)
    }
    const onChangeTournament = (event) => {
        setSelectedTournament(event.target.value)
    }
    const onChangeTeam = (event) => {
        setSelectedTeam(JSON.parse(event.target.value))
        setCardsReady(false)
    }

    const onSearch = async () => {
        await setAreCardsLoading(true)
        const cardsRes = await API.CARDS.getAllCardsByTeamAndSeason(selectedTeam.team_id, selectedSeason)
        await setCards(cardsRes.data)
        await setCardsReady(true)
        await setAreCardsLoading(false)
    }
    const setIsActive = async (isActive, card_id, short_name) => {
        const new_cards = cards.slice()
        new_cards.filter(c => c.card_id === card_id)[0].is_active = isActive
        setCards(new_cards)
        const res = await API.CARDS.setAvailability(card_id, isActive, cards.filter(c => c.card_id === card_id)[0].is_released)
        if(res.success) {
            toast.success(short_name + ' Modificato!')
        } else {
            const new_cards = cards.slice()
            new_cards.filter(c => c.card_id === card_id)[0].is_active = !isActive
            setCards(new_cards)
            toast.success(short_name + ': Errore!')
        }
    }
    const setIsReleased = async (isReleased, card_id, short_name) => {
        const new_cards = cards.slice()
        new_cards.filter(c => c.card_id === card_id)[0].is_released = isReleased
        setCards(new_cards)
        const res = await API.CARDS.setAvailability(card_id, cards.filter(c => c.card_id === card_id)[0].is_active, isReleased)
        if(res.success) {
            toast.success(short_name + ' Modificato!')
        } else {
            const new_cards = cards.slice()
            new_cards.filter(c => c.card_id === card_id)[0].is_released = !isReleased
            setCards(new_cards)
            toast.success(short_name + ': Errore!')
        }
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Cards
                </Typography>
                <Typography color="textPrimary">Modifica Disponibilità</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography color="textPrimary" variant="body2">
                    Da qui si possono modificare le disponibilità delle carte. Decidere se la figurina è sbustabile oppure no, se esiste oppure no.
                </Typography>
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15}}>
                <C.SELECTS.ActiveSeasonSelector value={selectedSeason} onChange={onChangeSeason} width="15rem" pt_sm={0} />
                <C.SELECTS.TournamentSelector value={selectedTournament} onChange={onChangeTournament} width="15rem" season={selectedSeason} />
                <C.SELECTS.TeamSelector value={JSON.stringify(selectedTeam)} onChange={onChangeTeam} width="15rem" tournament={selectedTournament} />
                <C.BUTTONS.SearchButton width="10rem" onClick={onSearch} />
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15, marginTop: 25, marginBottom: 15}}>
                {
                    selectedTeam !== "" ?
                        <Typography variant="h4" style={{display: 'flex', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', flexDirection: 'row'}}>
                            <img src={selectedTeam.image_url} alt={selectedTeam.team_name} height={40} style={{marginRight: 15}} />
                            <span>{selectedTeam.team_name}</span>
                            {
                                cardsReady ?
                                    <span style={{marginLeft: 10}}> - {cards.length} Giocatori [Attivi: {cards.filter(card => card.is_active).length}]</span>
                                    : undefined
                            }
                        </Typography>
                        : undefined
                }
            </Grid>
            <C.LOADERS.OverlayLoader active={areCardsLoading} style={{width: '100%'}}>
                <Grid item sm={12} style={{paddingLeft: 15, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {
                        cardsReady ?
                            cards.map((card) => {
                                return(
                                    <C.SURFACES.SPCardModAvailability
                                        card={card}
                                        key={card.card_id}
                                        setIsActive={(e, card_id, short_name) => setIsActive(e.target.checked, card_id, short_name)}
                                        setIsReleased={(e, card_id, short_name) => setIsReleased(e.target.checked, card_id, short_name)} />
                                )
                            })
                            : undefined
                    }
                </Grid>
            </C.LOADERS.OverlayLoader>
        </Grid>
    );
}