import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as C from '../../components'
import API from '../../api'
import {
    Breadcrumbs,
    Grid,
    Typography
} from "@material-ui/core";
import {SPCardCheckRarityPos} from "../../components/surfaces";
import {useToast} from '../../hooks';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    }
}));

export default function NoPosOrRarityCheck({props}) {
    const classes = useStyles()
    const [isActiveCardLoading, setIsActiveCardLoading] = React.useState(false)
    const [activeCardLoaded, setActiveCardLoaded] = React.useState(false)
    const [selectedSeason, setSelectedSeason] = React.useState()
    const [selectedTournament, setSelectedTournament] = React.useState()
    const [selectedTeam, setSelectedTeam] = React.useState()
    const [activeCard, setActiveCard] = React.useState("")
    const [ruoli, setRuoli] = React.useState()
    const [ruoliHasChanged, setRuoliHasChanged] = React.useState(false)
    const [rarityHasChanged, setRarityHasChanged] = React.useState(false)
    const [shirtNumberHasChanged, setShirtNumberHasChanged] = React.useState(false)
    const [availabilityHasChanged, setAvailabilityHasChanged] = React.useState(false)
    const [priority, setPriority] = React.useState(0)
    const toast = useToast()

    const getCard = async () => {
        await setIsActiveCardLoading(true)
        const card = await API.CARDS.getFirstCardWithoutPositionOrRarity(selectedTournament, selectedTeam.team_id)
        if(card.data !== null) {
            if (card.data.length > 0) {
                await setActiveCard(card.data[0])
                if(card.data[0].ruoli === null) {
                    card.data[0].ruoli = [{fl_position_id: 0, priority: 0}]
                }
                await setRuoli([
                    {
                        id: '1',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 1).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 1).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 1)[0].priority : 0
                    },
                    {
                        id: '2',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 2).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 2).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 2)[0].priority : 0
                    },
                    {
                        id: '3',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 3).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 3).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 3)[0].priority : 0
                    },
                    {
                        id: '4',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 4).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 4).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 4)[0].priority : 0
                    },
                    {
                        id: '5',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 5).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 5).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 5)[0].priority : 0
                    },
                    {
                        id: '6',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 6).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 6).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 6)[0].priority : 0
                    },
                    {
                        id: '7',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 7).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 7).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 7)[0].priority : 0
                    },
                    {
                        id: '8',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 8).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 8).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 8)[0].priority : 0
                    },
                    {
                        id: '9',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 9).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 9).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 9)[0].priority : 0
                    },
                    {
                        id: '10',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 10).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 10).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 10)[0].priority : 0
                    },
                    {
                        id: '11',
                        active: card.data[0].ruoli.filter(i => i.fl_position_id === 11).length > 0,
                        priority: card.data[0].ruoli.filter(i => i.fl_position_id === 11).length > 0 ? card.data[0].ruoli.filter(i => i.fl_position_id === 11)[0].priority : 0
                    }
                ])
                await setPriority(card.data[0].ruoli.length + 1)
                await setIsActiveCardLoading(false)
                await setActiveCardLoaded(true)
                await setRarityHasChanged(false)
                await setRuoliHasChanged(false)
                await setAvailabilityHasChanged(false)
            } else {
                if (card.success) {
                    toast.success("In questa squadra non ci sono più giocatori senza ruolo o rarità! Passa alla prossima!")
                } else {
                    toast.error("Qualcosa è andato storto. Riprova. Se l'errore persiste senti Fabio.")
                }
                await setIsActiveCardLoading(false)
                await setActiveCardLoaded(false)
            }
        } else {
            if (card.success) {
                toast.success("In questa squadra non ci sono più giocatori senza ruolo o rarità! Passa alla prossima!")
            } else {
                toast.error("Qualcosa è andato storto. Riprova. Se l'errore persiste senti Fabio.")
            }
            await setIsActiveCardLoading(false)
            await setActiveCardLoaded(false)
        }

    }
    const onSave = async () => {
        if(rarityHasChanged) {
            const res = await API.CARDS.setRarity(activeCard.card_id, activeCard.rarity)
            if(res.success) {
                toast.success('Rarità aggiornata!')
            } else {
                toast.error('Agg. Rarità FALLITO!')
            }
        }
        if(ruoliHasChanged) {
            const res_2 = await API.CARDS.setPositions(activeCard.card_id, ruoli.filter((i) => i.active === true).sort((a,b) => a.priority-b.priority))
            if(res_2.success) {
                toast.success('Ruoli salvati!')
            } else {
                toast.error('Inserimento Ruoli FALLITO!')
            }
        }
        if(availabilityHasChanged) {
            const res_3 = await API.CARDS.setAvailability(activeCard.card_id, activeCard.is_active, activeCard.is_released)
            if(res_3.success) {
                toast.success('Disponibilità Salvata')
            } else {
                toast.error('Inserimento Disponibilità FALLITO!')
            }
        }
        if(shirtNumberHasChanged) {
            const res_4 = await API.CARDS.setShirtNumber(activeCard.card_id, activeCard.shirt_number)
            if(res_4.success) {
                toast.success('Numero di Maglia Salvato')
            } else {
                toast.error('Inserimento numero di maglia FALLITO!')
            }
        }
    }
    const setRarity = async (rarity) => {
        const card = {...activeCard}
        card.rarity = rarity
        setActiveCard(card)
        setRarityHasChanged(true)
    }
    const setShirtNumber = async (number) => {
        const card = {...activeCard}
        card.shirt_number = number
        setActiveCard(card)
        setShirtNumberHasChanged(true)
    }
    const setIsActive = (isActive) => {
        const card = {...activeCard}
        card.is_active = isActive
        if(isActive) {
            card.is_released = isActive
        }
        setActiveCard(card)
        setAvailabilityHasChanged(true)
    }
    const setIsReleased = (isReleased) => {
        const card = {...activeCard}
        card.is_released = isReleased
        setActiveCard(card)
        setAvailabilityHasChanged(true)
    }
    const addOrRemovePosition = (e) => {
        const new_ruoli = ruoli.slice()
        new_ruoli.filter(i => i.id === e.target.name)[0].active = !new_ruoli.filter(i => i.id === e.target.name)[0].active
        if(new_ruoli.filter(i => i.id === e.target.name)[0].active === false) {
            new_ruoli.filter(i => i.id === e.target.name)[0].priority = 0
        } else {
            new_ruoli.filter(i => i.id === e.target.name)[0].priority = priority
            setPriority(prevPriority => prevPriority+1)
        }
        console.log(new_ruoli)
        setRuoli(new_ruoli)
        setRuoliHasChanged(true)
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Cards
                </Typography>
                <Typography color="textPrimary">Carte Senza Ruolo o Rarità</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography color="textPrimary" variant="body2">
                    Con questa funzionalità è possibile cercare i giocatori che non hanno la rarità o il ruolo e assegnarlo manualmente.
                </Typography>
            </Grid>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <C.SELECTS.ActiveSeasonSelector value={selectedSeason} onChange={(e) => setSelectedSeason(e.target.value)} width="8rem" pt_sm={0} />
                <C.SELECTS.TournamentSelector value={selectedTournament} onChange={(e) => setSelectedTournament(e.target.value)} season={selectedSeason} width="15rem" />
                <C.SELECTS.TeamSelector value={JSON.stringify(selectedTeam)} onChange={(e) => setSelectedTeam(JSON.parse(e.target.value))} tournament={selectedTournament} width="15rem" />
                <C.BUTTONS.SearchButton onClick={getCard} />
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15, display: 'flex', justifyContent: 'center'}}>
                <C.LOADERS.OverlayLoader active={isActiveCardLoading} style={{display: 'flex', justifyContent: 'center'}}>
                {
                    activeCardLoaded ?
                        <C.SURFACES.SPCardCheckRarityPos
                            card={activeCard}
                            onSave={onSave}
                            onNext={getCard}
                            setRarity={(rarity) => setRarity(rarity)}
                            addOrRemovePosition={(e) => addOrRemovePosition(e)}
                            setIsActive={(e) => setIsActive(e.target.checked)}
                            setIsReleased={(e) => setIsReleased(e.target.checked)}
                            ruoli={ruoli}
                            setShirtNumber={(e) => setShirtNumber(e.target.value)}
                        />
                        : undefined
                }
                </C.LOADERS.OverlayLoader>
            </Grid>
        </Grid>
    );
}