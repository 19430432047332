import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as C from 'components'
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import API from "api";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    }
}));

export default function CardsOverview() {
    const classes = useStyles()
    const [selectedSeason, setSelectedSeason] = React.useState("")
    const [selectedTournament, setSelectedTournament] = React.useState("")
    const [selectedTeam, setSelectedTeam] = React.useState("")
    const [cards, setCards] = React.useState()
    const [cardsReady, setCardsReady] = React.useState(false)
    const [areCardsLoading, setAreCardsLoading] = React.useState(false)

    const onChangeSeason = (event) => {
        setSelectedSeason(event.target.value)
    }
    const onChangeTournament = (event) => {
        setSelectedTournament(event.target.value)
    }
    const onChangeTeam = (event) => {
        setSelectedTeam(JSON.parse(event.target.value))
        setCardsReady(false)
    }

    const onSearch = async () => {
        await setAreCardsLoading(true)
        const cardsRes = await API.CARDS.getCardsByTeamAndSeason(selectedTeam.team_id, selectedSeason)
        await setCards(cardsRes.data)
        await setCardsReady(true)
        await setAreCardsLoading(false)
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Cards
                </Typography>
                <Typography color="textPrimary">Tutte le Carte</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography color="textPrimary" variant="body2">
                    Qui è possibile vedere un riepilogo di tutte le carte di FantaLegends.
                </Typography>
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15}}>
                <C.SELECTS.ActiveSeasonSelector value={selectedSeason} onChange={onChangeSeason} width="15rem" pt_sm={0} />
                <C.SELECTS.TournamentSelector value={selectedTournament} onChange={onChangeTournament} width="15rem" season={selectedSeason} />
                <C.SELECTS.TeamSelector value={JSON.stringify(selectedTeam)} onChange={onChangeTeam} width="15rem" tournament={selectedTournament} />
                <C.BUTTONS.SearchButton width="10rem" onClick={onSearch} />
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15, marginTop: 25, marginBottom: 15}}>
                {
                    selectedTeam !== "" ?
                        <>
                            <Typography variant="h4" style={{display: 'flex', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', flexDirection: 'row'}}>
                                <img src={selectedTeam.image_url} alt={selectedTeam.team_name} height={40} style={{marginRight: 15}} />
                                <span>{selectedTeam.team_name}</span>
                                {
                                    cardsReady ?
                                        <span style={{marginLeft: 10}}> - {cards.length} Giocatori</span>
                                        : undefined
                                }
                            </Typography>
                            <Typography style={{display: 'flex', justifyContent: 'center', alignContent: 'center', verticalAlign: 'center', flexDirection: 'row'}}>
                                [ID: {selectedTeam.team_id}]
                            </Typography>
                        </>
                        : undefined
                }
            </Grid>
            <C.LOADERS.OverlayLoader active={areCardsLoading} style={{width: '100%'}}>
                <Grid item sm={12} style={{paddingLeft: 15, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {
                            cardsReady ?
                            cards.map((card) => {
                                return(
                                    <C.SURFACES.SpotifySPCard card={card} key={card.card_id} />
                                )
                            })
                                : undefined
                        }
                </Grid>
            </C.LOADERS.OverlayLoader>
        </Grid>
    );
}