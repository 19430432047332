import React from 'react';
import * as C from '../../components'

import MatchViewer from "./matchViewerPage";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: {
        ...theme.mixins.toolbar,
    }
}));

export default function StatsRoutes() {
    const classes = useStyles()

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <C.ROUTES.PrivateRoute exact path="/stats/match_viewer" component={MatchViewer} />
        </main>
    );
}