import * as React from "react"
import {createTheme} from '@material-ui/core/styles'
import BebasNeueRegular from "../assets/font/BebasNeue-Regular.ttf"

const theme = createTheme({
    overrides:{
        typography: {
            fontFamily: [
                'Bebas Neue',
                'cursive',
                '"Open sans"',
                '"Helvetica Neue"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            fontSize: 12
        },
    }
})

export default theme