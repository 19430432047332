export const ROOT_ADMIN_ENDPOINT = "https://api2.fantalegends-services.com/admin";
export const ROOT_ENDPOINT = "https://api2.fantalegends-services.com/v2";

// export const ROOT_ENDPOINT = "http://192.168.1.8:3001/v2"
// export const ROOT_ADMIN_ENDPOINT = "http://192.168.1.8:3001/admin"
export const API_KEY = process.env.REACT_APP_API_KEY;

export function send(data, success, error){
    return {
        data: data,
        success: success,
        error: error
    }
}
