import axios from "axios";
import {ROOT_ENDPOINT, ROOT_ADMIN_ENDPOINT, send, API_KEY} from '../config/api.config'
import {useLocalStorage} from "hooks";

const ENDPOINT = ROOT_ENDPOINT + '/events'
const ADMIN_ENDPOINT = ROOT_ADMIN_ENDPOINT + '/events'

const User = async () => {
    const ls = useLocalStorage()
    const authToken = await ls.AUTH.getUserToken()
    const userID = ls.AUTH.getUserId()
    return {
        authToken,
        userID
    }
}

export const getActiveEvents = async (lite) => {
    console.log(lite)
    try {
        return await axios.get(ADMIN_ENDPOINT + '/active', // quelli non chiusi
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    'lite': lite || false
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getEventFormats = async () => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/formats',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const saveNewEvent = async (event) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/create',
            {
                event
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getStagesByEventID = async (eventID, lite) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/stages',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    'event': eventID,
                    'lite': lite || false
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getStageFormats = async () => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/stage_formats',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getRoundFormats = async () => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/round_formats',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const saveNewStage = async (stage) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/stage',
            {
                stage
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getRoundsByStageID = async (stageID) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/rounds',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    'stage': stageID
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const saveNewRound = async (round) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/round',
            {
                round
            },
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}