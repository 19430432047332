import axios from "axios";
import {ROOT_ENDPOINT, ROOT_ADMIN_ENDPOINT, send} from '../config/api.config'
import {useLocalStorage} from "../hooks";

const ENDPOINT = ROOT_ENDPOINT + '/matches'
const ADMIN_ENDPOINT = ROOT_ADMIN_ENDPOINT + '/matches'

const User = async () => {
    const ls = useLocalStorage()
    const authToken = await ls.AUTH.getUserToken()
    const userID = ls.AUTH.getUserId()
    return {
        authToken,
        userID
    }
}

export const getMatchesByRoundID = async (round) => {
    try {
        return await axios.get(ENDPOINT + '/matches_by_round',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    round
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getMatchPoints = async (match) => {
    try {
        return await axios.get(ENDPOINT + '/points_per_player',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    match
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getPlayerMatchPointsDetailsByPosition = async (card, position, match) => {
    try {
        return await axios.get(ENDPOINT + '/soccer_player_match_points_details',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    card,
                    position,
                    match
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}