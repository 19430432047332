import {firebase} from '../config/firebase.config'
import {useCryptoJS} from "./useCryptoJS"
import moment from 'moment'

export function useLocalStorage() {
    const AUTH = {}

    const CryptoJS = useCryptoJS()

    // setters
    AUTH.setUserToken = (token, key) => {
        // auth token
        const encryptedToken = CryptoJS.encryptDataWithKey(token, key)
        AUTH.setTokenExpiration()
        // AuthToken for Fanta Admin Access
        localStorage.setItem('_atfaa', encryptedToken)
    }
    AUTH.setUserId = (id) => {
        // User Id for Fanta Admin Access
        localStorage.setItem('_uifaa', id)
    }
    AUTH.setEmail = (email) => {
        // User Account Mail for Fanta Admin Access
        localStorage.setItem('_uamfaa', email)
    }
    AUTH.setTokenExpiration = () => {
        const expirationDate = moment().add(30,'minutes').unix();
        localStorage.setItem('_atfaae', expirationDate+'')
    }

    // getters
    AUTH.getUserToken = async () => {
        const t = localStorage.getItem('_atfaa')
        const key = AUTH.getUserId()
        const exp = AUTH.getTokenExpiration()
        const now = moment().unix();
        if(+exp <= +now) {
            await console.log('old_token: ' + t)
            await console.info('TOKEN EXPIRED')
            return await AUTH.refreshToken(key)
        } else {
            await console.info('TOKEN NOT EXPIRED')
            return CryptoJS.decryptDataWithKey(t, key)
        }
    }
    AUTH.getUserId = () => {
        return localStorage.getItem('_uifaa')
    }
    AUTH.getEmail = () => {
        return localStorage.getItem('_uamfaa')
    }
    AUTH.getTokenExpiration = () => {
        return localStorage.getItem('_atfaae')
    }

    AUTH.refreshToken = async (key) => {
        const new_token = await firebase.auth().currentUser.getIdToken(true)
        await console.log('nuovo token: ' + new_token)
        await console.log('id: ' + key)
        await AUTH.setUserToken(new_token, key)
        await console.log('TOKEN REFRESHED')
        return new_token
    }

    return { AUTH }
}