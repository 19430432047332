import React from 'react';

import { useHistory } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import LogoLight from '../../assets/images/logo/LightVS_scritta.png'
import * as C from '../../components'
import {useAuthentication, useLocalStorage} from "../../hooks";
import css from '../../styles/css'

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            La base operativa di Fantalegends.<br /> Dove il successo prende forma.
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: 'auto',
        background: css.gradients.flGradient_deg(45),
        maxWidth: 'none'
    }
}));

export default function LoginPage() {
    const classes = useStyles();
    const navigation = useHistory();
    const ls = useLocalStorage();
    const auth = useAuthentication()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)

    const onSubmit = async () => {
        if(email === '' || password === '') {
            alert('Non hai compilato tutti i campi.')
        } else {
            await setIsLoading(true)
            const loggedIn = await auth.signin(email, password)
            if(loggedIn) {
                setIsLoading(false)
                navigation.push("/")
            } else {
                setIsLoading(false)
                alert('Errore di Autenticazione.')
            }
        }
    }

    React.useEffect(() => {
        if(ls.AUTH.getEmail() && email === '') {
            setEmail(ls.AUTH.getEmail())
        }
    })

    return (
        <C.LOADERS.OverlayLoader active={isLoading}>
            <Container className={classes.container}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <img src={LogoLight} alt="logo" height={window.innerHeight/5} style={{paddingBottom: 40, minHeight: 100}} />
                        <form className={classes.form} noValidate>
                            <C.INPUTS.LoginTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <C.INPUTS.LoginTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{backgroundColor: 'rgba(255,255,255,0.08', borderRadius: 5}}
                            />
                            <C.BUTTONS.LoginButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={onSubmit}
                            >
                                Sign In
                            </C.BUTTONS.LoginButton>
                        </form>
                    </div>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Container>
            </Container>
        </C.LOADERS.OverlayLoader>
    );
}