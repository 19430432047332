import * as React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Top15Base from '../assets/socials/top_15_punti_fama_01.jpg'
import Top15Base_02 from '../assets/socials/top_15_punti_fama_02.jpg'
import API from '../api'
import html2canvas from 'html2canvas'

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function DashboardContent() {
    const classes = useStyles()
    const [top3rep, setTop3rep] = React.useState([])
    const [isReady, setIsReady] = React.useState(false)

    const ref = React.useRef()

    React.useEffect(() => {
        (async function () {
           const top3 = await API.STATS.getTop3ReputationPoints()
           if(top3.success) {
               setTop3rep(top3.data)
               setIsReady(true)
           }
        })()
    },[])

    const onButtonClick = () => {
        exportAsImage(document.getElementById("top15"), "top15.png")
    }
    const onButtonClick2 = () => {
        exportAsImage(document.getElementById("top15_2"), "top15_2.png")
    }

    const exportAsImage = async (el, imageFileName) => {
        const canvas = await html2canvas(el, { scale: 2 });
        const image = canvas.toDataURL("image/png", 1.0);
        downloadImage(image, imageFileName);
    };
    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };

    const Top15Position = ({top, left, rank}) => {
        const index = rank-1
        return(
            <div style={{height: 78, width: 423, position: "absolute", marginTop: top, marginLeft: left, display: "flex", flexDirection: "row"}}>
                <div style={{height: 78, width: 45, display: "flex", alignItems: "center"}}>
                    <Typography style={{fontFamily: "Bebas Neue", color: "white"}} variant={"h4"}>{rank}°</Typography>
                </div>
                <div style={{height: 78, width: 78}}>
                    <img
                        src={"./images/avatars/"+top3rep[index].img_url}
                        height={78}
                        width={78}
                    />
                    <img
                        src={"./images/ranks/"+top3rep[index].reputation_rank_img_url+".webp"}
                        height={35}
                        width={35}
                        style={{position: "relative", bottom: 35, left: 50}}
                    />
                </div>
                <div style={{height: 78, width: 17}}></div>
                <div style={{height: 78, width: 280}}>
                    <div style={{height: 37, width: 280, background: "#c81942", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography style={{fontFamily: "Bebas Neue", color: "#EEE"}} variant={"h5"}>{top3rep[index].username+"#"+top3rep[index].user_tag}</Typography>
                    </div>
                    <div style={{height: 4, width: 280}}></div>
                    <div style={{height: 37, width: 280, background: "#EEE", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Typography style={{fontFamily: "Bebas Neue", color: "#072336"}} variant={"h5"}>{top3rep[index].reputation_points+" PUNTI"}</Typography>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Typography paragraph>
                Da qua puoi ammirare la vastità del cazzo che me ne frega.<br />Oppure puoi cliccare in una delle voci qui a lato in attesa che questa pagina abbia un'utilità.
            </Typography>
            <button onClick={onButtonClick}>IMG</button>
            <div style={{height: 1080, width: 1080}} ref={ref} id="top15">
                {
                    isReady && (
                        <>
                            <Typography
                                style={{position: "absolute", marginTop: 450, fontFamily: "Bebas Neue", color: "white", width: 1080, textAlign: "center"}}
                                variant="h5"
                            >
                                {top3rep[0].username}#{top3rep[0].user_tag}
                            </Typography>
                            <Typography
                                style={{position: "absolute", marginTop: 470, fontFamily: "Bebas Neue", color: "#F99F16", width: 1080, textAlign: "center"}}
                                variant="h3"
                            >
                                {top3rep[0].team_name}
                            </Typography>
                            <Typography
                                style={{position: "absolute", marginTop: 510, fontFamily: "Bebas Neue", color: "white", width: 1080, textAlign: "center"}}
                                variant="h4"
                            >
                                {top3rep[0].reputation_points} punti
                            </Typography>
                            <img
                                src={"./images/avatars/"+top3rep[0].img_url}
                                style={{position: "absolute", height: 180, width: 180, marginTop: 548, marginLeft: 450}}
                            />
                            <img
                                src={"./images/ranks/"+top3rep[0].reputation_rank_img_url+".webp"}
                                height={90}
                                width={90}
                                style={{position: "absolute", marginTop: 650, marginLeft: 570}}
                            />
                            <Typography
                                style={{position: "absolute", marginTop: 485, fontFamily: "Bebas Neue", color: "white", width: 516, textAlign: "center"}}
                                variant="h5"
                            >
                                {top3rep[1].username}#{top3rep[1].user_tag}
                            </Typography>
                            <Typography
                                style={{position: "absolute", marginTop: 505, fontFamily: "Bebas Neue", color: "#F99F16", width: 516, textAlign: "center"}}
                                variant="h3"
                            >
                                {top3rep[1].team_name}
                            </Typography>
                            <Typography
                                style={{position: "absolute", marginTop: 545, fontFamily: "Bebas Neue", color: "white", width: 516, textAlign: "center"}}
                                variant="h4"
                            >
                                {top3rep[1].reputation_points} punti
                            </Typography>
                            <img
                                src={"./images/avatars/"+top3rep[1].img_url}
                                style={{position: "absolute", height: 180, width: 180, marginTop: 582, marginLeft: 168}}
                            />
                            <img
                                src={"./images/ranks/"+top3rep[0].reputation_rank_img_url+".webp"}
                                height={85}
                                width={85}
                                style={{position: "absolute", marginTop: 690, marginLeft: 280}}
                            />
                            <Typography
                                style={{position: "absolute", marginTop: 505, marginLeft: 574, fontFamily: "Bebas Neue", color: "white", width: 506, textAlign: "center"}}
                                variant="h5"
                            >
                                {top3rep[2].username}#{top3rep[2].user_tag}
                            </Typography>
                            <Typography
                                style={{position: "absolute", marginTop: 525, marginLeft: 574, fontFamily: "Bebas Neue", color: "#F99F16", width: 506, textAlign: "center"}}
                                variant="h3"
                            >
                                {top3rep[2].team_name}
                            </Typography>
                            <Typography
                                style={{position: "absolute", marginTop: 565, marginLeft: 574, fontFamily: "Bebas Neue", color: "white", width: 506, textAlign: "center"}}
                                variant="h4"
                            >
                                {top3rep[2].reputation_points} punti
                            </Typography>
                            <img
                                src={"./images/avatars/"+top3rep[2].img_url}
                                style={{position: "absolute", height: 180, width: 180, marginTop: 606, marginLeft: 737}}
                            />
                            <img
                                src={"./images/ranks/"+top3rep[0].reputation_rank_img_url+".webp"}
                                height={80}
                                width={80}
                                style={{position: "absolute", marginTop: 720, marginLeft: 850}}
                            />
                        </>
                    )
                }
                <img src={Top15Base} style={{height: 1080, width: 1080}} />
            </div>
            <button onClick={onButtonClick2}>IMG</button>
            <div style={{height: 1080, width: 1080}} ref={ref} id="top15_2">
                {
                    isReady && (
                        <>
                            <Top15Position top={336} left={93} rank={4} />
                            <Top15Position top={436} left={93} rank={5} />
                            <Top15Position top={536} left={93} rank={6} />
                            <Top15Position top={636} left={93} rank={7} />
                            <Top15Position top={736} left={93} rank={8} />
                            <Top15Position top={836} left={93} rank={9} />
                            <Top15Position top={336} left={552} rank={10} />
                            <Top15Position top={436} left={552} rank={11} />
                            <Top15Position top={536} left={552} rank={12} />
                            <Top15Position top={636} left={552} rank={13} />
                            <Top15Position top={736} left={552} rank={14} />
                            <Top15Position top={836} left={552} rank={15} />
                        </>
                    )
                }
                <img src={Top15Base_02} style={{height: 1080, width: 1080}} />
            </div>
        </main>
    )
}