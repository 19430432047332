import * as React from "react"
import {ToastContainer} from 'react-toastify'
import {Switch, Route, BrowserRouter} from 'react-router-dom'
import {AuthProvider, ThemeProvider} from "./stores/contexts";
import {MSTProvider, mstStore} from "./stores/mstStore"
import * as C from './components'

// PAGES
import LoginPage from "./pages/auth/loginPage";
import DashboardPage from "./pages/dashboardPage"

export default function App() {
    return(
        <BrowserRouter>
            <ThemeProvider>
                <AuthProvider>
                    <MSTProvider value={mstStore}>
                        <Switch>
                            <Route exact path="/login" component={LoginPage} />
                            <C.ROUTES.PrivateRoute path="/" component={DashboardPage} />
                        </Switch>
                        <ToastContainer />
                    </MSTProvider>
                </AuthProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}