import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as C from '../../components'
import {Breadcrumbs, Grid, Typography, Paper, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import API from '../../api'
import {ActiveSeasonSelector} from "../../components/selects";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
}));

export default function SeasonsTopPlayersPage() {
    const classes = useStyles()
    const [selectedSeason, setSelectedSeason] = React.useState("")
    const [selectedTournament, setSelectedTournament] = React.useState("")
    const [selectedPosition, setSelectedPosition] = React.useState("")
    const [selectedAlgorithm, setSelectedAlgorithm] = React.useState("")
    const [secondPosition, setSecondPosition] = React.useState('')
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [datas, setDatas] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)

    const onChangeSeason = (event) => {
        setSelectedSeason(event.target.value)
    }
    const onChangeTournament = (event) => {
        setSelectedTournament(event.target.value)
    }
    const onChangePosition = (position) => {
        setSelectedPosition(position)
        if(position === '2') { setSecondPosition(4) } else
        if(position === '5') { setSecondPosition(7) } else
        if(position === '8') { setSecondPosition(10) } else
        { setSecondPosition(0) }
    }
    const onChangeAlgorithm = (event) => {
        setSelectedAlgorithm(event.target.value)
    }
    const onSearch = async () => {
        if(selectedSeason==="" || selectedTournament==="" || selectedPosition==="" || selectedAlgorithm === "") {
            alert('Non hai fatto tutte le scelte.')
        } else {
            await setIsLoading(true)
            const data = await API.STATS.getTopSeasonPlayersToCheckRarity(selectedSeason, selectedTournament, selectedPosition, selectedAlgorithm, secondPosition)
            await setIsLoading(false)
            await setDatas(data.data)
            await setDataLoaded(true)
        }
    }

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    const TableResult = () => {
        const theme = useTheme()
        const matchMDdown = useMediaQuery(theme.breakpoints.down("md"))

        return(
            <TableContainer component={Paper}  style={{width: matchMDdown ? '100%' : '50%' }}>
                <C.LOADERS.OverlayLoader active={isLoading} style={{width: '100%'}} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Squadra</TableCell>
                            <TableCell>Posizione</TableCell>
                            <TableCell>Punti</TableCell>
                            <TableCell>Presenze</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {
                            dataLoaded ?
                                datas.map((i) => {
                                    return(
                                        <StyledTableRow>
                                            <TableCell>{i.short_name}</TableCell>
                                            <TableCell>{i.team_name}</TableCell>
                                            <TableCell>{i.fl_position_abbreviation}</TableCell>
                                            <TableCell>{i.points}</TableCell>
                                            <TableCell>{i.pres}</TableCell>
                                        </StyledTableRow>
                                    )
                                })
                                : undefined
                        }

                    </TableBody>
                </Table>
                </C.LOADERS.OverlayLoader>
            </TableContainer>
        )
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Cards
                </Typography>
                <Typography color="textPrimary">Migliori Giocatori (Season)</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography color="textPrimary" variant="body2">
                    Qui è possibile vedere la classifica dei migliori giocatori di una stagione, in relazione alla stagione precedente. Uno strumento utile per modificare eventualmente alcune rarità. Vengono fuori solo i giocatori che hanno giocato anche la stagione precedente a quella selezionata.
                </Typography>
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15}}>
                <C.SELECTS.ActiveSeasonSelector value={selectedSeason} onChange={onChangeSeason} width="8rem" pt_sm={0} required />
                <C.SELECTS.TournamentSelector value={selectedTournament} onChange={onChangeTournament} width="12rem" season={selectedSeason} />
                <C.SELECTS.GroupedPositionSelector value={selectedPosition} onChange={(e) => onChangePosition(e.target.value)} width="8rem" />
                <C.SELECTS.TopSeasonPlayersAlgorithmSelector value={selectedAlgorithm} onChange={onChangeAlgorithm} width="10rem" />
                <C.BUTTONS.SearchButton width="10rem" onClick={onSearch} />
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15, height: '100%', paddingTop: 20}}>
                <TableResult />
            </Grid>
        </Grid>
    );
}