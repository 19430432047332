import * as React from "react"
import {firebase} from '../config/firebase.config'
import {useLocalStorage} from "./useLocalStorage";
import API from '../api'
import {AuthContext} from '../stores/contexts'

export function useAuthentication() {

    const ls = useLocalStorage()
    const auth = React.useContext(AuthContext)

    const signin = async (email, password) => {
        try {
            let user = await firebase.auth().signInWithEmailAndPassword(email,password)
            if(user) {
                let authToken = await firebase.auth().currentUser.getIdToken(true)
                let uid = user.user.uid
                const isAdmin = await API.USERS.checkAdmin(uid, authToken)
                console.log(isAdmin)
                if(isAdmin.success) {
                    await ls.AUTH.setUserId(uid)
                    await ls.AUTH.setUserToken(authToken, uid)
                    await ls.AUTH.setEmail(email)
                } else {
                    auth.setIsLogged(false)
                    alert('Non sei un amministratore.')
                    return false
                }
            }
            return true
        } catch (e) {
            alert(e)
            return false
        }
    }

    return {signin}
}