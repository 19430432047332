import axios from "axios";
import {ROOT_ENDPOINT, ROOT_ADMIN_ENDPOINT, send} from '../config/api.config'
import {useLocalStorage} from "../hooks";

const ENDPOINT = ROOT_ENDPOINT + '/user'
const ADMIN_ENDPOINT = ROOT_ADMIN_ENDPOINT + '/user'

export const checkAdmin = async (user_id, token) => {
    try {
        return await axios.post(ADMIN_ENDPOINT + '/checkAdmin',
            {
                "userid": user_id
            },
            {
                headers: {
                    'userid': user_id,
                    'authorization': token
                }
            }
        ).then((res) => {
            console.log(res)
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        console.log(error)
        return send(null, false, error.response.data.message)
    }
}