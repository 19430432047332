import axios from "axios";
import {ROOT_ENDPOINT, ROOT_ADMIN_ENDPOINT, send} from '../config/api.config'
import {useLocalStorage} from "../hooks";

const ENDPOINT = ROOT_ENDPOINT + '/teams'
const ADMIN_ENDPOINT = ROOT_ADMIN_ENDPOINT + '/teams'

const User = async () => {
    const ls = useLocalStorage()
    const authToken = await ls.AUTH.getUserToken()
    const userID = ls.AUTH.getUserId()
    return {
        authToken,
        userID
    }
}

export const getTeamsByTournament = async (tournament) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/teams_by_tournament',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    'tournament': tournament
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}