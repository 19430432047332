import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Breadcrumbs,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";
import Icon from '@mdi/react'
import { mdiCalendarPlus } from '@mdi/js';
import moment from 'moment'

import * as C from 'components'
import API from 'api'
import css from 'styles/css'
import EventForm from './forms/eventForm'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex'
    },
    root: {
        width: '100%',
    },
}));

const columns = [
    { id: 'name', label: 'Nome' },
    { id: 'description', label: 'Descrizione' },
    { id: 'format', label: 'Formato' },
    { id: 'start', label: 'Inizio / Fine' },
    { id: 'fee', label: 'Costo' },
    { id: 'n_stages', label: 'Fasi Create / Totali' },
    { id: 'matchmaking', label: 'Matchmaking' },
    { id: 'status', label: 'Stato' },
    { id: 'actions', label: 'Azioni' }
];

export default function Events() {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)
    const [events, setEvents] = React.useState()
    const [eventsLoaded, setEventsLoaded] = React.useState(false)

    const [addNewDialogIsOpen, setAddNewDialogIsOpen] = React.useState(false)
    const onCloseAddNewDialog = () => {
        setAddNewDialogIsOpen(false)
        getAllActiveEvents().then(() => setIsLoading(false))
    }
    const onOpenAddNewDialog = () => {
        setAddNewDialogIsOpen(true)
    }

    // GESTISCE LA TABELLA
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllActiveEvents = async () => {
        await setIsLoading(true)
        const events = await API.EVENTS.getActiveEvents(false)
        await setEvents(events.data)
        await setEventsLoaded(true)
    }

    React.useEffect(() => {
        getAllActiveEvents().then(() => { setIsLoading(false) })
    },[])

    return (
        <Grid container className={classes.container}>
            <Grid item sm={12} style={{marginLeft: 15}}>
                <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingBottom: 10}}>
                    <Typography color="inherit">
                        Events
                    </Typography>
                    <Typography color="textPrimary">Tutti gli Eventi</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item sm={12} style={{marginTop: 10, marginLeft: 15}}>
                <C.BUTTONS.ActionButton
                    label="NUOVO EVENTO"
                    IconComponent={() => <Icon path={mdiCalendarPlus}  style={{height: 20, width: 20, marginRight: 15}} />}
                    width={200}
                    onClick={onOpenAddNewDialog}
                />
            </Grid>
            <C.LOADERS.OverlayLoader active={isLoading} style={{width: '100%'}}>
                <Paper style={{marginLeft: 15, marginTop: 20}} className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    eventsLoaded ?
                                    events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.event_id}>
                                                <TableCell key={1}>{row.event_name}</TableCell>
                                                <TableCell key={2}>{row.event_description}</TableCell>
                                                <TableCell key={3}>{row.event_format_name}</TableCell>
                                                <TableCell key={4}>
                                                    <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography><strong>DA: </strong>{moment(row.event_start).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                        <Typography><strong>A: </strong>{moment(row.event_end).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell key={6}>
                                                    <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                                    {
                                                        row.entry_fee.map(i => <Typography>{i.qty} {i.name}</Typography>)
                                                    }
                                                    </Grid>
                                                </TableCell>
                                                <TableCell key={7} style={{color: row.event_n_stages > row.fasi_create ? 'red' : 'black'}}>{row.fasi_create}/{row.event_n_stages}</TableCell>
                                                <TableCell key={11}>{row.event_has_matchmaking === 1 ? 'SI' : 'NO'}</TableCell>
                                                <TableCell key={12}>{row.event_status_name.toUpperCase()}</TableCell>
                                                <TableCell key={13}>[DETTAGLI / MODIFICA / ELIMINA / PUBBLICA]</TableCell>
                                            </TableRow>
                                        );
                                    }) : undefined
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={eventsLoaded ? events.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </C.LOADERS.OverlayLoader>
            <C.DIALOGS.FullScreen
                title="Aggiungi Nuovo Evento"
                open={addNewDialogIsOpen}
                onClose={onCloseAddNewDialog}
                ContentComponent={() => <EventForm />}
            />
        </Grid>
    );
}
