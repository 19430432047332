import * as React from "react";
import {types} from "mobx-state-tree";
import NavigationStore from './navigationStore'

const RootModel = types.model({
    navigation: NavigationStore
})

export const mstStore = RootModel.create({
    navigation: {isDrawerMobileOpen: false, selectedMenuItem: 1}
});

const RootStoreContext = React.createContext(null);

export const MSTProvider = RootStoreContext.Provider;

export function useMst() {
    const store = React.useContext(RootStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}