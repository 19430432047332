import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import * as C from 'components'
import {
    Breadcrumbs,
    Button, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    Table, TableContainer, TableCell, TableHead, TableBody, TableRow
} from "@material-ui/core";
import API from 'api'
import css from 'styles/css'

import grey from "@material-ui/core/colors/grey";
import Info from "@material-ui/icons/Info";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: {
        ...theme.mixins.toolbar,
    },
    container: {
        display: 'flex',
    },
    buttonItem: {
        display: 'flex',
        flex: 1,
        paddingRight: 10
    },
    matchContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    matchRow: {
        display: 'flex',
        height: '2.5rem',
        width: '100%',
        maxWidth: '400px',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        marginBottom: theme.spacing(1),
        backgroundColor: grey[50]
    },
    matchItem: {
        display: 'flex',
        flex: 5,
        justifyContent: 'center'
    },
    teamName: {
        textAlign: 'center',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: '1.4rem'
    },
    positionPointsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: '1.3rem'
    },
    text: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: '0.7rem'
    },
    playerRowContainer: {
        marginBottom: 5,
        padding: 5,
        borderBottom: '1px solid #DDD'
    },
    infoIcon: {
        height: '1.3rem',
        cursor: 'pointer',
        color: css.palette.fl3_alpha(.7)
    }
}));

export default function MatchViewer() {
    const classes = useStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // SELECTED
    const [selectedSeason, setSelectedSeason] = React.useState("")
    const [selectedTournament, setSelectedTournament] = React.useState("")
    const [selectedRound, setSelectedRound] = React.useState("")
    const [selectedMatch, setSelectedMatch] = React.useState("")

    // DATA LOADED
    const [matchesLoaded, setMatchesLoaded] = React.useState(false)
    const [matchDetailsLoaded, setMatchDetailsLoaded] = React.useState(false)
    const [matchPointsDetailsLoaded, setMatchPointsDetailsLoaded] = React.useState(false)

    // DATA
    const [matches, setMatches] = React.useState("")
    const [matchDetails, setMatchDetails] = React.useState("")
    const [matchPointsDetails, setMatchPointsDetails] = React.useState("")

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState("")

    const onChangeSeason = (e) => {
        setSelectedSeason(e.target.value)
    }
    const onChangeTournament = (e) => {
        setSelectedTournament(e.target.value)
    }
    const onChangeRound = (e) => {
        setSelectedRound(e.target.value)
    }

    const onSearch = async () => {
        await setMatchesLoaded(false)
        const matches = await API.MATCHES.getMatchesByRoundID(selectedRound)
        await setMatches(matches.data)
        await setMatchesLoaded(true)
    }
    const onSelectMatch = async (match) => {
        await setMatchDetailsLoaded(false)
        await setSelectedMatch(match)
        const matchDetails = await API.MATCHES.getMatchPoints(match.match_id)
        await setMatchDetails(matchDetails.data)
        await setMatchDetailsLoaded(true)
    }

    const onSelectPlayerDetails = async (player, position_id, position, points) => {
        await setMatchPointsDetailsLoaded(false)
        const details = await API.MATCHES.getPlayerMatchPointsDetailsByPosition(player.card_id, position_id, selectedMatch.match_id)
        await setDialogTitle(player.match_name.toUpperCase() + " - " + position + " => " + points + " pts.")
        await setMatchPointsDetails(details.data)
        await setDialogIsOpen(true)
        await setMatchPointsDetailsLoaded(true)
    }
    const closeDialog = () => {
        setDialogIsOpen(false)
    }

    const Match = ({match}) => {
        return(
            <Paper className={classes.matchRow}>
                <Grid className={classes.matchItem}>
                    <img src={match.home_team_img} style={{width: 20, marginRight: 10}} alt={match.home_team_name} /> {match.home_team_name} {match.home_score} - {match.away_score} {match.away_team_name} <img src={match.away_team_img} style={{width: 25, marginLeft: 10}} alt={match.away_team_name} />
                </Grid>
                <Grid className={classes.buttonItem}>
                    <Button onClick={() => onSelectMatch(match)} variant="contained" style={{height: '1.5rem'}}>GUARDA</Button>
                </Grid>
            </Paper>
        )
    }
    const MatchDetails = () => {
        return(
            <Grid container>
                <Grid item xs={12} md={6} style={{padding: 5}}>
                    <Grid className={classes.teamName}>
                        <img src={selectedMatch.home_team_img} alt={selectedMatch.home_team_name} style={{width: 25, marginRight: 10}} />
                        {selectedMatch.home_team_name.toUpperCase()}
                    </Grid>
                    <Grid className={classes.title}>TITOLARI</Grid>
                    {
                        matchDetails
                            .filter(i => i.team_id === selectedMatch.home_team_id && i.type === 'lineup')
                            .map((i) => <PlayerRow key={i.card_id+i.fl_formation_id} player={i} />)
                    }
                    <Grid className={classes.title}>PANCHINA</Grid>
                    {
                        matchDetails
                            .filter(i => i.team_id === selectedMatch.home_team_id && i.type === 'bench')
                            .map((i) => <PlayerRow key={i.card_id+i.fl_formation_id} player={i} />)
                    }
                </Grid>
                <Grid item xs={12} md={6} style={{padding: 5}}>
                    <Grid className={classes.teamName}>
                        {selectedMatch.away_team_name.toUpperCase()}
                        <img src={selectedMatch.away_team_img} alt={selectedMatch.away_team_name} style={{width: 25, marginLeft: 10}} />
                    </Grid>
                    <Grid className={classes.title}>TITOLARI</Grid>
                    {
                        matchDetails
                            .filter(i => i.team_id === selectedMatch.away_team_id && i.type === 'lineup')
                            .map((i) => <PlayerRow key={i.card_id+i.fl_formation_id} player={i} />)
                    }
                    <Grid className={classes.title}>PANCHINA</Grid>
                    {
                        matchDetails
                            .filter(i => i.team_id === selectedMatch.away_team_id && i.type === 'bench')
                            .map((i) => <PlayerRow key={i.card_id+i.fl_formation_id} player={i} />)
                    }
                </Grid>
            </Grid>
        )
    }
    const PlayerRow = ({player}) => {
        return(
            <Grid container className={classes.playerRowContainer}>
                <Grid item xs={8} className={classes.text} style={{display: 'flex', alignItems: 'center'}}>
                    {player.match_name.toUpperCase()}
                </Grid>
                <Grid item xs={4} className={classes.positionPointsContainer}>
                    {
                        player.positions.map(i => {
                            return(
                                <Grid container key={i.fl_position_id} style={{display: 'flex', alignItems: 'center', paddingTop: 3, paddingBottom: 3}}>
                                    <Grid item xs={5} className={classes.text}>
                                        {i.fl_position_abbreviation}
                                    </Grid>
                                    <Grid item xs={5} className={classes.text} style={{display: 'flex', alignItems: 'center'}}>
                                        {i.points}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Info
                                            className={classes.infoIcon}
                                            style={{display: 'flex', alignItems: 'center'}}
                                            onClick={() => onSelectPlayerDetails(player, i.fl_position_id, i.fl_position_abbreviation, i.points)}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        )
    }
    const MatchPointsDetails = () => {
        return(
            <Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>AZIONE</strong></TableCell>
                                <TableCell><strong>QTA</strong></TableCell>
                                <TableCell><strong>PUNTI</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
        <C.TABLES.StyledTableRow><TableCell>shots_total</TableCell><TableCell> {matchPointsDetails.stats[0].shots_total}</TableCell><TableCell>{matchPointsDetails.points[0].shots_total}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>shots_on_goal</TableCell><TableCell> {matchPointsDetails.stats[0].shots_on_goal}</TableCell><TableCell>{matchPointsDetails.points[0].shots_on_goal}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>shots_outside_goal</TableCell><TableCell> {matchPointsDetails.stats[0].shots_outside_goal}</TableCell><TableCell>{matchPointsDetails.points[0].shots_outside_goal}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>goals_scored</TableCell><TableCell> {matchPointsDetails.stats[0].goals_scored}</TableCell><TableCell>{matchPointsDetails.points[0].goals_scored}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>goals_conceded</TableCell><TableCell> {matchPointsDetails.stats[0].goals_conceded}</TableCell><TableCell>{matchPointsDetails.points[0].goals_conceded}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>owngoals</TableCell><TableCell> {matchPointsDetails.stats[0].owngoals}</TableCell><TableCell>{matchPointsDetails.points[0].owngoals}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>assists</TableCell><TableCell> {matchPointsDetails.stats[0].assists}</TableCell><TableCell>{matchPointsDetails.points[0].assists}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>fouls_drawn</TableCell><TableCell> {matchPointsDetails.stats[0].fouls_drawn}</TableCell><TableCell>{matchPointsDetails.points[0].fouls_drawn}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>fouls_committed</TableCell><TableCell> {matchPointsDetails.stats[0].fouls_committed}</TableCell><TableCell>{matchPointsDetails.points[0].fouls_committed}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>yellow_cards</TableCell><TableCell> {matchPointsDetails.stats[0].yellow_cards}</TableCell><TableCell>{matchPointsDetails.points[0].yellow_cards}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>red_cards</TableCell><TableCell> {matchPointsDetails.stats[0].red_cards}</TableCell><TableCell>{matchPointsDetails.points[0].red_cards}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>yellow_red_cards</TableCell><TableCell> {matchPointsDetails.stats[0].yellow_red_cards}</TableCell><TableCell>{matchPointsDetails.points[0].yellow_red_cards}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>crosses_total</TableCell><TableCell> {matchPointsDetails.stats[0].crosses_total}</TableCell><TableCell>{matchPointsDetails.points[0].crosses_total}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>crosses_success</TableCell><TableCell> {matchPointsDetails.stats[0].crosses_success}</TableCell><TableCell>{matchPointsDetails.points[0].crosses_success}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>crosses_fail</TableCell><TableCell> {matchPointsDetails.stats[0].crosses_fail}</TableCell><TableCell>{matchPointsDetails.points[0].crosses_fail}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>passes</TableCell><TableCell> {matchPointsDetails.stats[0].passes}</TableCell><TableCell>{matchPointsDetails.points[0].passes}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>passes_success</TableCell><TableCell> {matchPointsDetails.stats[0].passes_success}</TableCell><TableCell>{matchPointsDetails.points[0].passes_success}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>passes_failed</TableCell><TableCell> {matchPointsDetails.stats[0].passes_failed}</TableCell><TableCell>{matchPointsDetails.points[0].passes_failed}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>passes_accuracy</TableCell><TableCell> {matchPointsDetails.stats[0].passes_accuracy}</TableCell><TableCell>{matchPointsDetails.points[0].passes_accuracy}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>passes_key</TableCell><TableCell> {matchPointsDetails.stats[0].passes_key}</TableCell><TableCell>{matchPointsDetails.points[0].passes_key}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>dribbles_attempt</TableCell><TableCell> {matchPointsDetails.stats[0].dribbles_attempt}</TableCell><TableCell>{matchPointsDetails.points[0].dribbles_attempt}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>dribbles_success</TableCell><TableCell> {matchPointsDetails.stats[0].dribbles_success}</TableCell><TableCell>{matchPointsDetails.points[0].dribbles_success}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>dribbles_fail</TableCell><TableCell> {matchPointsDetails.stats[0].dribbles_fail}</TableCell><TableCell>{matchPointsDetails.points[0].dribbles_fail}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>dribbles_past</TableCell><TableCell> {matchPointsDetails.stats[0].dribbles_past}</TableCell><TableCell>{matchPointsDetails.points[0].dribbles_past}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>duels_total</TableCell><TableCell> {matchPointsDetails.stats[0].duels_total}</TableCell><TableCell>{matchPointsDetails.points[0].duels_total}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>duels_success</TableCell><TableCell> {matchPointsDetails.stats[0].duels_success}</TableCell><TableCell>{matchPointsDetails.points[0].duels_success}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>duels_fail</TableCell><TableCell> {matchPointsDetails.stats[0].duels_fail}</TableCell><TableCell>{matchPointsDetails.points[0].duels_fail}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>aerials_won</TableCell><TableCell> {matchPointsDetails.stats[0].aerials_won}</TableCell><TableCell>{matchPointsDetails.points[0].aerials_won}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>punches</TableCell><TableCell> {matchPointsDetails.stats[0].punches}</TableCell><TableCell>{matchPointsDetails.points[0].punches}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>offsides</TableCell><TableCell> {matchPointsDetails.stats[0].offsides}</TableCell><TableCell>{matchPointsDetails.points[0].offsides}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>saves</TableCell><TableCell> {matchPointsDetails.stats[0].saves}</TableCell><TableCell>{matchPointsDetails.points[0].saves}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>saves_inside_the_box</TableCell><TableCell> {matchPointsDetails.stats[0].saves_inside_the_box}</TableCell><TableCell>{matchPointsDetails.points[0].saves_inside_the_box}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>pen_scored</TableCell><TableCell> {matchPointsDetails.stats[0].pen_scored}</TableCell><TableCell>{matchPointsDetails.points[0].pen_scored}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>pen_missed</TableCell><TableCell> {matchPointsDetails.stats[0].pen_missed}</TableCell><TableCell>{matchPointsDetails.points[0].pen_missed}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>pen_saved</TableCell><TableCell> {matchPointsDetails.stats[0].pen_saved}</TableCell><TableCell>{matchPointsDetails.points[0].pen_saved}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>pen_committed</TableCell><TableCell> {matchPointsDetails.stats[0].pen_committed}</TableCell><TableCell>{matchPointsDetails.points[0].pen_committed}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>pen_won</TableCell><TableCell> {matchPointsDetails.stats[0].pen_won}</TableCell><TableCell>{matchPointsDetails.points[0].pen_won}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>woodwork_hit</TableCell><TableCell> {matchPointsDetails.stats[0].woodwork_hit}</TableCell><TableCell>{matchPointsDetails.points[0].woodwork_hit}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>tackles</TableCell><TableCell> {matchPointsDetails.stats[0].tackles}</TableCell><TableCell>{matchPointsDetails.points[0].tackles}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>blocks</TableCell><TableCell> {matchPointsDetails.stats[0].blocks}</TableCell><TableCell>{matchPointsDetails.points[0].blocks}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>interceptions</TableCell><TableCell> {matchPointsDetails.stats[0].interceptions}</TableCell><TableCell>{matchPointsDetails.points[0].interceptions}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>clearances</TableCell><TableCell> {matchPointsDetails.stats[0].clearances}</TableCell><TableCell>{matchPointsDetails.points[0].clearances}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>dispossessed</TableCell><TableCell> {matchPointsDetails.stats[0].dispossessed}</TableCell><TableCell>{matchPointsDetails.points[0].dispossessed}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>minutes_played</TableCell><TableCell> {matchPointsDetails.stats[0].minutes_played}</TableCell><TableCell>{matchPointsDetails.points[0].minutes_played}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>clean_sheet</TableCell><TableCell> {matchPointsDetails.stats[0].clean_sheet}</TableCell><TableCell>{matchPointsDetails.points[0].clean_sheet}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>team_goals_conceded_while_on_pitch</TableCell><TableCell> {matchPointsDetails.stats[0].team_goals_conceded_while_on_pitch}</TableCell><TableCell>{matchPointsDetails.points[0].team_goals_conceded_while_on_pitch}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>team_goals_scored_while_on_pitch</TableCell><TableCell> {matchPointsDetails.stats[0].team_goals_scored_while_on_pitch}</TableCell><TableCell>{matchPointsDetails.points[0].team_goals_scored_while_on_pitch}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>base_points</TableCell><TableCell>{matchPointsDetails.stats[0].base_points}</TableCell><TableCell>{matchPointsDetails.points[0].base_points}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>won_more_than_2_diff</TableCell><TableCell>{matchPointsDetails.stats[0].won_more_than_2_diff}</TableCell><TableCell>{matchPointsDetails.points[0].won_more_than_2_diff}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>won_up_2_diff</TableCell><TableCell>{matchPointsDetails.stats[0].won_up_2_diff}</TableCell><TableCell>{matchPointsDetails.points[0].won_up_2_diff}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>lost_up_2_diff</TableCell><TableCell>{matchPointsDetails.stats[0].lost_up_2_diff}</TableCell><TableCell>{matchPointsDetails.points[0].lost_up_2_diff}</TableCell></C.TABLES.StyledTableRow>
        <C.TABLES.StyledTableRow><TableCell>lost_more_than_2_diff</TableCell><TableCell>{matchPointsDetails.stats[0].lost_more_than_2_diff}</TableCell><TableCell>{matchPointsDetails.points[0].lost_more_than_2_diff}</TableCell></C.TABLES.StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Analytics
                </Typography>
                <Typography color="textPrimary">Dettaglio Punti per Partita</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography variant="body2" color="textPrimary">Seleziona la Stagione, il Campionato e la Giornata per vedere l'elenco delle partite. Clicca poi su "GUARDA" per vedere l'elenco dei giocatori e quanti punti hanno preso in ognuna delle loro posizioni. Infine, cliccanto sull (i) accando a ogni giocatore si apre un popup con il dettaglio del punteggio.</Typography>
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15}}>
                <C.SELECTS.ActiveSeasonSelector value={selectedSeason} onChange={onChangeSeason} width="8rem" pt_sm={0} />
                <C.SELECTS.TournamentSelector value={selectedTournament} onChange={onChangeTournament} season={selectedSeason} width="15rem" />
                <C.SELECTS.RoundSelector value={selectedRound} onChange={onChangeRound} tournament={selectedTournament} width="10rem" />
                <C.BUTTONS.SearchButton width="10rem" onClick={onSearch} />
            </Grid>
            <Grid item sm={12} style={{marginLeft: 15, marginTop: 30, display: 'flex', flexDirection: 'row'}}>
                <Grid item sm={12} md={5} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {
                        matchesLoaded ?
                            matches.map((m) => {
                                return (<Match key={m.match_id} match={m} />)
                            })
                            : undefined
                    }
                </Grid>
                <Grid item sm={12} md={7}>
                    {
                        matchDetailsLoaded ?
                            <MatchDetails />
                            : undefined
                    }
                </Grid>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                open={dialogIsOpen}
                onClose={closeDialog}
                aria-labelledby="title"
            >
                <DialogTitle id="title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            matchPointsDetailsLoaded ?
                                <MatchPointsDetails />
                                : undefined
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" autoFocus>
                        CHIUDI
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}