import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Breadcrumbs, Grid, Paper, Typography} from "@material-ui/core";

import * as C from 'components'
import API from 'api'
import css from 'styles/css'
import {useToast} from "hooks";

const useStyles = makeStyles((theme) => (   {
    container: {
        display: 'flex',
    },
    rarityTotContainer: {
        display: 'flex',
        flexGrow: 1,
        padding: 20,
        justifyContent: 'center'
    },
    rarityTot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    rarity: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 20,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 18,
        paddingRight: 18,
        alignItems: 'center'
    },
    legendary: {
        background: css.palette.legendary(.8)
    },
    epic: {
        background: css.palette.epic(.8)
    },
    rare: {
        background: css.palette.rare(.8)
    },
    common: {
        background: css.palette.common(.8)
    }
}));

export default function BulkRarityMod() {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)
    const [cardsLoaded, setCardsLoaded] = React.useState(false)
    const [selectedSeason, setSelectedSeason] = React.useState()
    const [selectedTournament, setSelectedTournament] = React.useState()
    const [selectedPosition, setSelectedPosition] = React.useState()
    const [secondPosition, setSecondPosition] = React.useState('')
    const [selectedAlgorithm, setSelectedAlgorithm] = React.useState(0)
    const [cards, setCards] = React.useState()
    const toast = useToast()

    const onPositionChange = (position) => {
        setSelectedPosition(position)
        if(position === '2') { setSecondPosition(4) } else
        if(position === '5') { setSecondPosition(7) } else
        if(position === '8') { setSecondPosition(10) } else
        { setSecondPosition(0) }
    }
    const onSearch = async () => {
        await setIsLoading(true)
        const res = await API.STATS.getTopSeasonPlayersToCheckRarity(selectedSeason, selectedTournament, selectedPosition, selectedAlgorithm, secondPosition)
        if(res.success) {
            await setCards(res.data)
            await setIsLoading(false)
            await setCardsLoaded(true)
        } else {
            await setIsLoading(false)
            toast.error('Errore nel caricamento...')
        }
    }
    const setRarity = async (card_id, rarity) => {
        await setIsLoading(true)
        const res = await API.CARDS.setRarity(card_id, rarity)
        if(res.success) {
            toast.success('Rarità modificata!')
            const copyCards = cards.slice()
            copyCards.filter(c => c.card_id === card_id)[0].rarity = rarity;
            await setCards(copyCards)
            await setIsLoading(false)
        } else {
            toast.error('Errore di connessione...')
            setIsLoading(false)
        }
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Cards
                </Typography>
                <Typography color="textPrimary">Modifica Rarità (Bulk)</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography color="textPrimary" variant="body2">
                    Da qui si possono modificare le rarità per ruolo. In ordine si trovano: <strong>NOME, SQUADRA, PUNTI, PRESENZE</strong>.
                </Typography>
            </Grid>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <C.SELECTS.ActiveSeasonSelector value={selectedSeason} onChange={(e) => setSelectedSeason(e.target.value)} width="8rem" pt_sm={0} />
                <C.SELECTS.TournamentSelector value={selectedTournament} onChange={(e) => setSelectedTournament(e.target.value)} season={selectedSeason} width="15rem" />
                <C.SELECTS.GroupedPositionSelector value={selectedPosition} onChange={(e) => onPositionChange(e.target.value)} width="8rem" />
                {/*<C.SELECTS.TopSeasonPlayersAlgorithmSelector value={selectedAlgorithm} onChange={(e) => setSelectedAlgorithm(e.target.value)} width="10rem" />*/}
                <C.BUTTONS.SearchButton onClick={onSearch} />
            </Grid>
            <Grid className={classes.rarityTotContainer}>
                <Paper className={classes.rarityTot}>
                    <Typography className={[classes.rarity, classes.legendary]}>{cardsLoaded ? cards.filter(c => c.rarity === 'L').length : 0}</Typography>
                    <Typography className={[classes.rarity, classes.epic]}>{cardsLoaded ? cards.filter(c => c.rarity === 'E').length : 0}</Typography>
                    <Typography className={[classes.rarity, classes.rare]}>{cardsLoaded ? cards.filter(c => c.rarity === 'R').length : 0}</Typography>
                    <Typography className={[classes.rarity, classes.common]}>{cardsLoaded ? cards.filter(c => c.rarity === 'C').length : 0}</Typography>
                    <Typography className={[classes.rarity]}>TOT: {cardsLoaded ? cards.length : 0}<br />TOT Ass.: {cardsLoaded ? cards.filter(c => c.rarity !== null).length : 0}</Typography>
                </Paper>
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15}}>
                <C.LOADERS.OverlayLoader active={isLoading} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    {
                        cardsLoaded ?
                            cards.map((i) => <C.SURFACES.SPCardCheckRarityBulk key={i.card_id} card={i} setRarity={(card_id, rarity) => setRarity(card_id, rarity)} />)
                            : undefined
                    }
                </C.LOADERS.OverlayLoader>
            </Grid>
        </Grid>
    );
}