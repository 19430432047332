import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as C from '../../components'
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import API from "../../api";
import {useToast} from "../../hooks";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    }
}));

export default function CheckTransfers() {
    const classes = useStyles()
    const [title, setTitle] = React.useState("GIOCATORI IN USCITA")
    const [transfers, setTransfers] = React.useState()
    const [filteredTransfers, setFilteredTransfers] = React.useState()
    const [isReady, setIsReady] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    const toast = useToast()

    const onSelectOut = () => {
        setFilteredTransfers(transfers.filter(t => t.from_card_id !== "" && t.from_card_id !== null && t.from_deactivated === 0 && t.is_valid_from === -1))
        setTitle("Giocatori in Uscita")
    }
    const onSelectIn = () => {
        setFilteredTransfers(transfers.filter(t => t.to_card_created === 0 && t.to_soccer_player_id !== null && t.is_valid_to === -1))
        setTitle("Giocatori in Entrata")
    }

    React.useEffect(() => {
        (async function getTransfers() {
            await setIsLoading(true)
            const res_transfers = await API.CARDS.getTransfers()
            await setTransfers(res_transfers.data)
            await setFilteredTransfers(res_transfers.data.filter(t => t.from_card_id !== "" && t.from_card_id !== null && t.from_deactivated === 0 && t.is_valid_from === -1))
            await setIsReady(true)
            await setIsLoading(false)
        })()
    },[])

    const setValidOrInvalidFrom = async (value, id) => {
        await setIsLoading(true)
        const res = await API.CARDS.setTransfersFromValidity(value, id)
        if(res.success) {
            toast.success('Modificato')
            const copyTransfers = transfers.slice()
            copyTransfers.filter(c => c.id_transfer === id)[0].is_valid_from = value;
            await setTransfers(copyTransfers)
            setFilteredTransfers(copyTransfers.filter(t => t.from_card_id !== "" && t.from_card_id !== null && t.from_deactivated === 0 && t.is_valid_from === -1))
            await setIsLoading(false)
        } else {
            toast.error('Errore!')
            setIsLoading(false)
        }
    }
    const setValidOrInvalidTo = async (value, id) => {
        await setIsLoading(true)
        const res = await API.CARDS.setTransfersToValidity(value, id)
        if(res.success) {
            toast.success('Modificato')
            const copyTransfers = transfers.slice()
            copyTransfers.filter(c => c.id_transfer === id)[0].is_valid_to = value;
            await setTransfers(copyTransfers)
            setFilteredTransfers(copyTransfers.filter(t => t.to_card_created === 0 && t.to_soccer_player_id !== null && t.is_valid_to === -1))
            await setIsLoading(false)
        } else {
            toast.error('Errore!')
            setIsLoading(false)
        }
    }

    return (
        <Grid container className={classes.container}>
            <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                <Typography color="inherit">
                    Cards
                </Typography>
                <Typography color="textPrimary">Check Transfers</Typography>
            </Breadcrumbs>
            <Grid item sm={12} style={{paddingBottom: 30, paddingLeft: 15}}>
                <Typography color="textPrimary" variant="body2">
                    Check manuale dei trasferimenti.
                </Typography>
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15}}>
                <C.BUTTONS.ActionButton label="GIOCATORI IN USCITA" onClick={onSelectOut} width={250} />
                <C.BUTTONS.ActionButton label="GIOCATORI IN ENTRATA" onClick={onSelectIn} width={250} containerStyle={{marginLeft: 10}} />
            </Grid>
            <Grid item sm={12} style={{paddingLeft: 15, textAlign: "center"}}>
                <Typography style={{fontSize: 24}}>{title.toUpperCase()}</Typography>
            </Grid>
            <C.LOADERS.OverlayLoader active={isLoading} style={{width: '100%'}}>
                {
                    isReady ?
                    <Grid item sm={12} style={{paddingLeft: 15, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {
                            filteredTransfers.map( (i) => {
                                    if (title === "GIOCATORI IN USCITA") {
                                        return (<C.SURFACES.SPCardTransferCheckFrom card={i} setValidOrInvalid={(value, id) => setValidOrInvalidFrom(value, id)}/>)
                                    } else {
                                        return (<C.SURFACES.SPCardTransferCheckTo card={i} setValidOrInvalid={(value, id) => setValidOrInvalidTo(value, id)}/>)
                                    }
                                }
                            )
                        }
                    </Grid>
                        : undefined
                }
            </C.LOADERS.OverlayLoader>
        </Grid>
    );
}