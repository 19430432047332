import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Breadcrumbs,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";

import Icon from "@mdi/react";
import {mdiSourceBranchPlus} from "@mdi/js";

import * as C from 'components'
import API from 'api'
import moment from "moment";
import StageForm from "./forms/stageForm";
import {useToast} from "../../hooks";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    root: {
        width: '100%'
    }
}));

const columns = [
    { id: 'number', label: 'N°' },
    { id: 'name', label: 'Nome' },
    { id: 'description', label: 'Descrizione' },
    { id: 'format', label: 'Formato' },
    { id: 'start', label: 'Inizio / Fine' },
    { id: 'n_stages', label: 'Round Creati / Totali' },
    { id: 'matchmaking', label: 'Matchmaking' },
    { id: 'status', label: 'Stato' },
    { id: 'actions', label: 'Azioni' }
];

export default function Stages() {
    const classes = useStyles()
    const toast = useToast()
    const [isLoading, setIsLoading] = React.useState(false)
    const [eventID, setEventID] = React.useState('')
    const [searchedEventID, setSearchedEventID] = React.useState('')
    const [stages, setStages] = React.useState()
    const [stagesLoaded, setStagesLoaded] = React.useState(false)

    const [addNewDialogIsOpen, setAddNewDialogIsOpen] = React.useState(false)
    const onCloseAddNewDialog = () => {
        setAddNewDialogIsOpen(false)
        onSearch().then(() => {})
    }
    const onOpenAddNewDialog = () => {
        if(eventID !== '' && eventID === searchedEventID) {
            setAddNewDialogIsOpen(true)
        } else {
            toast.error('Non hai selezionato alcun evento oppure hai selezionato un evento ma non hai cercato se ha già delle fasi.')
        }
    }

    // GESTISCE LA TABELLA
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onSearch = async () => {
        const event = eventID
        await setStagesLoaded(false)
        await setIsLoading(true)
        const stages = await API.EVENTS.getStagesByEventID(event)
        if(stages.success) {
            await setStages(stages.data)
            await setIsLoading(false)
            await setStagesLoaded(true)
            await setSearchedEventID(event)
        } else {
            toast.error('Errore nel caricamento dei dati.')
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid item sm={12} style={{marginLeft: 15}}>
                <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                    <Typography color="inherit">
                        Events
                    </Typography>
                    <Typography color="textPrimary">Fasi</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item sm={12} style={{marginTop: 10, marginLeft: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Grid>
                    <C.SELECTS.Events value={eventID} onChange={(e) => setEventID(e.target.value)} label="Eventi" />
                    <C.BUTTONS.SearchButton onClick={onSearch} />
                </Grid>
                <Grid>
                    <C.BUTTONS.ActionButton
                        label="NUOVA FASE"
                        IconComponent={() => <Icon path={mdiSourceBranchPlus}  style={{height: 20, width: 20, marginRight: 15}} />}
                        width={200}
                        onClick={onOpenAddNewDialog}
                    />
                </Grid>
            </Grid>
            <C.LOADERS.OverlayLoader active={isLoading} style={{width: '100%'}}>
                <Paper style={{marginLeft: 15, marginTop: 20}} className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    stagesLoaded ?
                                        stages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.stage_id}>
                                                    <TableCell key={0}>{row.stage_number}</TableCell>
                                                    <TableCell key={1}>{row.stage_name}</TableCell>
                                                    <TableCell key={2}>{row.stage_description}</TableCell>
                                                    <TableCell key={3}>{row.stage_format_name}</TableCell>
                                                    <TableCell key={4}>
                                                        <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                                            <Typography><strong>DA: </strong>{moment(row.stage_start).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                            <Typography><strong>A: </strong>{moment(row.stage_end).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell key={7} style={{color: row.stage_n_rounds > row.round_creati ? 'red' : 'black'}}>{row.round_creati}/{row.stage_n_rounds}</TableCell>
                                                    <TableCell key={11}>{row.stage_has_matchmaking === 1 ? 'SI' : 'NO'}</TableCell>
                                                    <TableCell key={12}>{row.stage_status_name.toUpperCase()}</TableCell>
                                                    <TableCell key={13}>[DETTAGLI / MODIFICA / ELIMINA / PUBBLICA]</TableCell>
                                                </TableRow>
                                            );
                                        }) : undefined
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </C.LOADERS.OverlayLoader>
            <C.DIALOGS.FullScreen
                title="Aggiungi una Nuova Fase"
                open={addNewDialogIsOpen}
                onClose={onCloseAddNewDialog}
                ContentComponent={() => <StageForm eventID={eventID} />}
            />
        </Grid>
    );
}