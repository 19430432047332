import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Breadcrumbs,
    Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";

import Icon from "@mdi/react";
import {mdiSourceBranchPlus} from "@mdi/js";

import * as C from 'components'
import API from 'api'
import moment from "moment";
import RoundForm from "./forms/roundForm";
import {useToast} from "../../hooks";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    root: {
        width: '100%'
    }
}));

const columns = [
    { id: 'number', label: 'N°' },
    { id: 'name', label: 'Nome' },
    { id: 'description', label: 'Descrizione' },
    { id: 'format', label: 'Formato' },
    { id: 'start', label: 'Inizio / Fine' },
    { id: 'lineup', label: 'Consegna Formazioni' },
    { id: 'matchmaking', label: 'Matchmaking' },
    { id: 'status', label: 'Stato' },
    { id: 'actions', label: 'Azioni' }
];

export default function Rounds() {
    const classes = useStyles()
    const toast = useToast()
    const [isLoading, setIsLoading] = React.useState(false)
    const [eventID, setEventID] = React.useState('')
    const [searchedEventID, setSearchedEventID] = React.useState('')
    const [stageID, setStageID] = React.useState()
    const [serachedStageID, setSerachedStageID] = React.useState()
    const [rounds, setRounds] = React.useState()
    const [roundsLoaded, setRoundsLoaded] = React.useState(false)

    const [addNewDialogIsOpen, setAddNewDialogIsOpen] = React.useState(false)
    const onCloseAddNewDialog = () => {
        setAddNewDialogIsOpen(false)
        // getAllActiveEvents().then(() => setIsLoading(false))
    }
    const onOpenAddNewDialog = () => {
        if(eventID !== '' && stageID !== '' && eventID === searchedEventID && stageID === serachedStageID) {
            setAddNewDialogIsOpen(true)
        } else {
            toast.error('Non hai selezionato tutto e cercato.')
        }
    }

    // GESTISCE LA TABELLA
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onSearch = async () => {
        const event = eventID
        const stage = stageID
        await setRoundsLoaded(false)
        await setIsLoading(true)
        const rounds = await API.EVENTS.getRoundsByStageID(stageID)
        if(rounds.success) {
            await setRounds(rounds.data)
            await setIsLoading(false)
            await setRoundsLoaded(true)
            await setSearchedEventID(event)
            await setSerachedStageID(stage)
        } else {
            toast.error('Errore nel caricamento dei dati.')
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid item sm={12} style={{marginLeft: 15}}>
                <Breadcrumbs aria-label="breadcrumb" separator=">" style={{paddingLeft: 15, paddingBottom: 10}}>
                    <Typography color="inherit">
                        Events
                    </Typography>
                    <Typography color="textPrimary">Giornate</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item sm={12} style={{marginTop: 10, marginLeft: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Grid style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                    <C.SELECTS.Events value={eventID} onChange={(e) => setEventID(e.target.value)} label="Eventi" width="35%" />
                    <C.SELECTS.Stages value={stageID} onChange={(e) => setStageID(e.target.value)} label="Fasi" event={eventID} width="35%" />
                    <C.BUTTONS.SearchButton onClick={onSearch} mt={5} />
                </Grid>
                <Grid>
                    <C.BUTTONS.ActionButton
                        label="NUOVA GIORNATA"
                        IconComponent={() => <Icon path={mdiSourceBranchPlus}  style={{height: 20, width: 20, marginRight: 15}} />}
                        width={200}
                        onClick={onOpenAddNewDialog}
                    />
                </Grid>
            </Grid>
            <C.LOADERS.OverlayLoader active={isLoading} style={{width: '100%'}}>
                <Paper style={{marginLeft: 15, marginTop: 20}} className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    roundsLoaded ?
                                        rounds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.round_id}>
                                                    <TableCell key={0}>{row.round_number}</TableCell>
                                                    <TableCell key={1}>{row.round_name}</TableCell>
                                                    <TableCell key={2}>{row.round_description}</TableCell>
                                                    <TableCell key={3}>{row.round_format_name}</TableCell>
                                                    <TableCell key={4}>
                                                        <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                                            <Typography><strong>DA: </strong>{moment(row.round_start).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                            <Typography><strong>A: </strong>{moment(row.round_end).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell key={4}>
                                                        <Grid style={{display: 'flex', flexDirection: 'column'}}>
                                                            <Typography><strong>DA: </strong>{moment(row.deliver_lineup_start).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                            <Typography><strong>A: </strong>{moment(row.deliver_lineup_end).format("ddd DD MMMM HH:mm").toString()}</Typography>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell key={11}>{row.round_has_matchmaking === 1 ? 'SI' : 'NO'}</TableCell>
                                                    <TableCell key={12}>{row.round_status_name.toUpperCase()}</TableCell>
                                                    <TableCell key={13}>[DETTAGLI / MODIFICA / ELIMINA / PUBBLICA]</TableCell>
                                                </TableRow>
                                            );
                                        }) : undefined
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </C.LOADERS.OverlayLoader>
            <C.DIALOGS.FullScreen
                title="Aggiungi un Nuovo Round"
                open={addNewDialogIsOpen}
                onClose={onCloseAddNewDialog}
                ContentComponent={() => <RoundForm stageID={stageID} />}
            />
        </Grid>
    );
}