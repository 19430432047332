import * as React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Icon from '@mdi/react'
import { mdiCardPlus, mdiDelete, mdiContentSave } from '@mdi/js'
import { sortBy } from 'lodash'
import moment from 'moment'

import * as C from "components";
import css from 'styles/css'
import API from 'api'
import {useToast} from 'hooks'

export default function EventForm(props) {
    const [eventName, setEventName] = React.useState('')
    const [eventDescription, setEventDescription] = React.useState('')
    const [eventStart, setEventStart] = React.useState('')
    const [eventEnd, setEventEnd] = React.useState('')
    const [enrollmentStart, setEnrollmentStart] = React.useState()
    const [enrollmentEnd, setEnrollmentEnd] = React.useState()
    const [eventFormat, setEventFormat] = React.useState('')
    const [eventFormatName, setEventFormatName] = React.useState('')
    const [numberOfStages, setNumberOfStages] = React.useState()
    const [numberOfGroups, setNumberOfGroups] = React.useState()
    const [groupSize, setGroupSize] = React.useState()
    const [hasMatchmaking, setHasMatchmaking] = React.useState()
    const [mmStart, setMmStart] = React.useState('')
    const [mmEnd, setMmEnd] = React.useState('')
    const [entryFees, setEntryFees] = React.useState([])
    const [entryFeeCost, setEntryFeeCost] = React.useState()
    const [entryFeeUnit, setEntryFeeUnit] = React.useState()
    const [entryFeeUnitName, setEntryFeeUnitName] = React.useState()
    const [prizes, setPrizes] = React.useState([])
    const [fromRank, setFromRank] = React.useState()
    const [toRank, setToRank] = React.useState()
    const [prizeQty, setPrizeQty] = React.useState()
    const [prizeItem, setPrizeItem] = React.useState()
    const [prizeItemName, setPrizeItemName] = React.useState()


    const useStyles = makeStyles((theme) => ({
        multipleInput: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        fromToRank: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        },
        rankPrizeCat: {
            borderTop: '1px solid '+css.palette.fl4_alpha(.5),
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    }));

    const classes = useStyles()
    const toast = useToast()

    const addFee = () => {
        const new_entry_fee = entryFees.slice()
        new_entry_fee.push({
            id: entryFeeUnit,
            name: entryFeeUnitName,
            qty: entryFeeCost
        })
        setEntryFees(new_entry_fee)
    }
    const addRankPrizes = () => {
        if(+toRank < +fromRank) {
            alert("ERRORE: A non può essere maggiore di DA!")
        } else {
            const new_prizes = prizes.slice()
            new_prizes.push(
                {
                    from: fromRank,
                    to: toRank,
                    prizes: []
                }
            )
            setPrizes(new_prizes)
        }
    }
    const removeRankPrizes = (from) => {
        const new_prizes = prizes.filter((i) => i.from !== from)
        setPrizes(new_prizes)
    }
    const addPrizesToRank = (from) => {
        const new_prizes = prizes.slice()
        new_prizes.filter((i) => i.from === from)[0].prizes.push({
            id: prizeItem,
            name: prizeItemName,
            qty: prizeQty
        })
        setPrizes(new_prizes)
    }
    const onSave = async () => {
        const event = {
            name: eventName,
            description: eventDescription,
            start: moment(eventStart).utc().format().toString(),
            end: moment(eventEnd).utc().format().toString(),
            enrollmentStart: moment(enrollmentStart).utc().format().toString(),
            enrollmentEnd: moment(enrollmentEnd).utc().format().toString(),
            format: eventFormat,
            stages: numberOfStages,
            groups: numberOfGroups,
            groupSize: groupSize,
            hasMM: hasMatchmaking,
            mmStart: moment(mmStart).utc().format().toString(),
            mmEnd: moment(mmEnd).utc().format().toString(),
            fees: entryFees,
            prizes: prizes
        }
        const res = await API.EVENTS.saveNewEvent(event)
        if(res.success) {
            toast.success('Evento Salvato!')
        } else {
            toast.error('Errore nel salvataggio!')
        }
    }

    return(
        <Grid style={{display: 'flex', justifyContent: 'space-around'}}>
            <Grid item sm={12} md={4}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <C.INPUTS.Text label="Nome" value={eventName} onChange={(e) => setEventName(e.target.value)} width="100%" />
                        <C.INPUTS.Text label="Descrizione" value={eventDescription} onChange={(e) => setEventDescription(e.target.value)} width="100%" multiline rows={4}/>
                        <Grid item sm={12} className={classes.multipleInput}>
                            <C.INPUTS.DateTime label="Inizio" value={eventStart} onChange={(e) => setEventStart(e.target.value)} width="48%" />
                            <C.INPUTS.DateTime label="Fine" value={eventEnd} onChange={(e) => setEventEnd(e.target.value)} width="48%" />
                        </Grid>
                        <Grid item sm={12} className={classes.multipleInput}>
                            <C.INPUTS.DateTime label="Inizio Iscrizioni" value={enrollmentStart} onChange={(e) => setEnrollmentStart(e.target.value)} width="48%" />
                            <C.INPUTS.DateTime label="Fine Iscrizioni" value={enrollmentEnd} onChange={(e) => setEnrollmentEnd(e.target.value)} width="48%" />
                        </Grid>
                        <C.SELECTS.EventFormats label="Formato" value={eventFormat} onChange={(e) => { setEventFormat(e.target.value); setEventFormatName(e.target.options[e.target.selectedIndex].text) }} width="100%" pt={10} />
                        <Grid item sm={12} className={classes.multipleInput} style={{marginTop: 5}}>
                            <C.INPUTS.Number label="N° Fasi" value={numberOfStages} onChange={(e) => setNumberOfStages(e.target.value)} width="30%" />
                            <C.INPUTS.Number label="N° Gruppi" value={numberOfGroups} onChange={(e) => setNumberOfGroups(e.target.value)} width="30%" />
                            <C.INPUTS.Number label="Grandezza Gruppi" value={groupSize} onChange={(e) => setGroupSize(e.target.value)} width="30%" />
                        </Grid>
                        <Grid item sm={12} className={classes.multipleInput} style={{marginTop: 5}}>
                            <C.SELECTS.YesOrNo label="MM?" value={hasMatchmaking} onChange={(e) => setHasMatchmaking(e.target.value)} width="15%" pt={24} />
                            <C.INPUTS.DateTime label="Inizio MM" value={mmStart} onChange={(e) => setMmStart(e.target.value)} width="40%" />
                            <C.INPUTS.DateTime label="Fine MM" value={mmEnd} onChange={(e) => setMmEnd(e.target.value)} width="40%" />
                        </Grid>
                        <Grid item sm={12} className={classes.multipleInput} style={{marginTop: 5}}>
                            <C.INPUTS.Number label="Costo" value={entryFeeCost} onChange={(e) => setEntryFeeCost(e.target.value)} width="40%" />
                            <C.SELECTS.Currencies label="Valuta" value={entryFeeUnit} onChange={(e) => {setEntryFeeUnit(e.target.value); setEntryFeeUnitName(e.target.options[e.target.selectedIndex].text)}} width="40%" pt={24} />
                            <C.BUTTONS.ActionButton label="" onClick={addFee} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="10%" containerStyle={{marginTop: 24}} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item sm={12} md={4}>
            <Paper style={{width: '100%', padding: 20}} elevation={3}>
                <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <Typography variant="h6">PREMI</Typography>
                    <Grid item sm={12} className={classes.fromToRank} style={{marginTop: 5}}>
                        <C.INPUTS.Number label="Da #" value={fromRank} onChange={(e) => setFromRank(e.target.value)} width="25%" />
                        <C.INPUTS.Number label="A #" value={toRank} onChange={(e) => setToRank(e.target.value)} width="25%" />
                        <C.BUTTONS.ActionButton label="" onClick={addRankPrizes} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="10%" containerStyle={{marginTop: 24}} />
                    </Grid>
                    <Grid item sm={12} style={{marginTop:15}}>
                    {
                        sortBy(prizes, [function(o) { return +o.from; }]).map((i) => {
                            return(
                                <Grid item sm={12} className={classes.rankPrizeCat}>
                                    <Grid item sm={2}>
                                    {
                                        i.from === i.to ?
                                            <Typography>Al {i.from}°</Typography>
                                            :
                                            <Typography>Dal {i.from}° al {i.to}°</Typography>
                                    }
                                    </Grid>
                                    <Grid item sm={9} style={{display: 'flex', flexDirection: 'column'}}>
                                        <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
                                            <C.INPUTS.Number label="Qty" value={prizeQty} onChange={(e) => setPrizeQty(e.target.value)} width="20%" />
                                            <C.SELECTS.Prizes
                                                label="Premio"
                                                value={prizeItem}
                                                onChange={(e) => {setPrizeItem(e.target.value); setPrizeItemName(e.target.options[e.target.selectedIndex].text)}}
                                                pt={17}
                                                width="40%"
                                            />
                                            <C.BUTTONS.ActionButton label="" onClick={() => addPrizesToRank(i.from)} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="20%" />
                                        </Grid>
                                        <Grid item sm={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10}}>
                                            {
                                                i.prizes.map((p) => <Typography key={p.id}>{p.qty} {p.name}</Typography>)
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={1}>
                                        <C.BUTTONS.ActionButton
                                            label=""
                                            onClick={() => removeRankPrizes(i.from)}
                                            IconComponent={() => <Icon path={mdiDelete} style={{height: 20, width: 20}} /> }
                                            width="100%"
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
            <Grid item sm={12} md={3}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h4">RIEPILOGO</Typography>
                        <Typography variant="h6">NOME: {eventName}</Typography>
                        <Typography variant="h6">DESCRIZIONE: {eventDescription}</Typography>
                        <Typography variant="h6">Inizia il: {eventStart}</Typography>
                        <Typography variant="h6">Finisce il: {eventEnd}</Typography>
                        <Typography variant="h6">Formato: {eventFormatName}</Typography>
                        <Typography variant="h6">Fasi: {numberOfStages}</Typography>
                        <Typography variant="h6">Gruppi: {numberOfGroups}</Typography>
                        <Typography variant="h6">Utenti per Gruppo: {groupSize}</Typography>
                        <Typography variant="h4" style={{marginTop: 15}}>MATCHMAKING</Typography>
                        <Typography variant="h6">Ha il matchmaking? {hasMatchmaking === 1 ? 'SI' : 'NO'}</Typography>
                        <Typography variant="h6">Inizio MM: {mmStart}</Typography>
                        <Typography variant="h6">Fine MM: {mmEnd}</Typography>
                        <Typography variant="h4" style={{marginTop: 15}}>ENTRY FEES</Typography>
                        {
                            entryFees.map(i => <Typography>{i.qty} {i.name}</Typography>)
                        }
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
                        <C.BUTTONS.ActionButton
                            label="SALVA"
                            onClick={onSave}
                            IconComponent={() => <Icon path={mdiContentSave} style={{height: 20, width: 20}} />}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
