import C from 'crypto-js'

export function useCryptoJS() {
    const encryptDataWithKey = (data, key) => {
        return C.AES.encrypt(data, key).toString()
    }

    const decryptDataWithKey = (data, key) => {
        const bytes = C.AES.decrypt(data,key)
        return bytes.toString(C.enc.Utf8)
    }

    return {
        encryptDataWithKey, decryptDataWithKey
    }
}

