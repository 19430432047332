import * as React from "react"

const css = {
    palette: {
        fl1: "rgba(204,49,128,1)",
        fl2: "rgba(254,103,110,1)",
        fl3: "rgba(253,143,82,1)",
        fl4: "rgba(255,189,113,1)",
        fl5: "rgba(255,220,162,1)",
        fl1_alpha: alpha => `rgba(204,49,128,${alpha})`,
        fl2_alpha: alpha => `rgba(254,103,110,${alpha})`,
        fl3_alpha: alpha => `rgba(253,143,82,${alpha})`,
        fl4_alpha: alpha => `rgba(255,189,113,${alpha})`,
        fl5_alpha: alpha => `rgba(255,220,162,${alpha})`,
        text: opacity => `rgba(0,0,0,${opacity || '.7'})`,
        textWhite: opacity => `rgba(255,255,255,${opacity || '.7'})`,
        legendary: opacity => `rgba(255,220,162,${opacity || '.7'})`,
        epic: opacity => `rgba(204,49,128,${opacity || '.7'})`,
        rare: opacity => `rgba(36,117,205,${opacity || '.7'})`,
        common: opacity => `rgba(232,232,232,${opacity || '.7'})`
    },
    gradients: {
        flGradient_deg: deg => `linear-gradient(${deg}deg, rgba(204,49,128,1) 0%, rgba(223,121,81,1) 50%, rgba(255,242,1,1) 100%)`,
        flGradient_deg_alpha: (deg, alpha) => `linear-gradient(${deg}deg, rgba(204,49,128,${alpha}) 0%, rgba(223,121,81,${alpha}) 50%, rgba(255,242,1,${alpha}) 100%)`,
        gradient_fl_2_3: (deg, alpha) => `linear-gradient(${deg}deg, rgba(254,103,110,${alpha}) 0%, rgba(253,143,82,${alpha}) 100%)`,
        gradient_fl_1_2_3: (deg, alpha) => `linear-gradient(${deg}deg, rgba(204,49,128,${alpha}) 0%, rgba(254,103,110,${alpha}) 50%, rgba(253,143,82,${alpha}) 100%)`,
        gradient_fl_3_4_5: (deg, alpha) => `linear-gradient(${deg}deg, rgba(253,143,82,${alpha}) 0%, rgba(255,189,113,${alpha}) 50%, rgba(255,220,162,${alpha}) 100%)`
    }
}

export default css