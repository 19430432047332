import { types } from "mobx-state-tree"

const NavigationStore = types
    .model("NavigationStore",
        {
            isDrawerMobileOpen: types.boolean,
            selectedMenuItem: types.integer
        }
    )
    .actions( self => ({
        handleDrawerMobileOpen(){
            self.isDrawerMobileOpen = !self.isDrawerMobileOpen
        },
        handleSelectedMenuItem(n){
            self.selectedMenuItem = n
        }
    }))

export default NavigationStore