import * as React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import css from "../styles/css";

export const FullScreen = ({title, ContentComponent, open, onClose}) => {
    return(
        <Dialog
            fullScreen={true}
            open={open}
            onClose={onClose}
            aria-labelledby="title"
        >
            <DialogTitle id="title" style={{display: 'flex', justifyContent: 'center'}}>{title}</DialogTitle>
            <DialogContent>
                <ContentComponent />
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={onClose} style={{background: css.gradients.gradient_fl_2_3(45,.7)}} autoFocus variant="outlined">
                    CHIUDI
                </Button>
            </DialogActions>
        </Dialog>
    )
}