import * as CARDS from './cards'
import * as TOURNAMENTS from './tournaments'
import * as TEAMS from './teams'
import * as USERS from './users'
import * as SEASONS from './seasons'
import * as STATS from './stats'
import * as MATCHES from './matches'
import * as EVENTS from './events'

const api = {
    CARDS,
    EVENTS,
    MATCHES,
    SEASONS,
    STATS,
    TOURNAMENTS,
    TEAMS,
    USERS
}

export default api