import * as React from "react"
import {Route, Redirect} from "react-router-dom"
import {AuthContext} from "../stores/contexts";

export const PrivateRoute = (props) => {
    const {isLogged} = React.useContext(AuthContext);
    return (
        isLogged ?
            <Route exact={props.exact} path={props.path} component={props.component} /> :
            <Redirect to={"/login"} />
    );
};