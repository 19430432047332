import * as React from 'react'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

export const OverlayLoader = ({active, children, style}) => {
    return(
        <LoadingOverlay
            active={active}
            spinner={<BounceLoader css={{display: 'flex'}} color='#df7851' />}
            styles={{
                wrapper: {
                    width: '100%',
                    ...style
                }
            }}
        >
            {children}
        </LoadingOverlay>
    )
}