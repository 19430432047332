import * as React from "react"
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {useMst} from "../stores/mstStore";
import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import * as CONSTANTS from "../config/constants"
import css from '../styles/css'
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    useMediaQuery,
    Button,
    ButtonGroup,
    Grid,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Switch,
    FormControl,
    Paper,
    Badge,
    DialogTitle,
    DialogContent, DialogContentText, DialogActions, Dialog
} from "@material-ui/core";

// ICONS
import Icon from '@mdi/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mdiPageNextOutline, mdiDockWindow, mdiContentSaveOutline, mdiAlphaT, mdiCached, mdiGoogle } from '@mdi/js';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import * as C from "./index";

const useStyles = makeStyles((theme) => ({
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${CONSTANTS.DRAWER_WITH}px)`,
            marginLeft: CONSTANTS.DRAWER_WITH,
        },
        '&.MuiAppBar-root': {
            backgroundColor: 'black',
            background: css.gradients.flGradient_deg_alpha(90, .85),
            boxShadow: 'none'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

const TransfermarktDialog = ({url, open, onClose, title}) => {
    return(
        <Dialog
            fullScreen={true}
            open={open}
            onClose={onClose}
            aria-labelledby="title"
        >
            <DialogTitle id="title" style={{display: 'flex', justifyContent: 'center'}}>{title}</DialogTitle>
            <DialogContent>
                <iframe src={url} sandbox='allow-scripts allow-same-origin' height='100%' width='100%' />
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={onClose} style={{background: css.gradients.gradient_fl_2_3(45,.7)}} autoFocus variant="outlined">
                    CHIUDI
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const MainBar = () => {
    const store = useMst()
    const classes = useStyles()

    return(
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => store.navigation.handleDrawerMobileOpen()}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Typography noWrap style={{fontWeight: 'thin'}}>
                    PANNELLO DI AMMINISTRAZIONE
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export const SpotifySPCard = ({card}) => {
    const theme = useTheme()
    const matches_down_md = useMediaQuery(theme.breakpoints.down('md'))
    const match_sm_down = useMediaQuery(theme.breakpoints.down('sm'))

    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            width: matches_down_md ? '95%' : '24%',
            marginTop: 10,
            marginRight: 10,
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            '&.MuiCardContent-root:last-child': {
                paddingBottom: 0
            },
            minHeight: 150
        },
        cover: {
            minWidth: 140,
            height: 170
        },
        tmktButton: {
            display: 'flex',
            marginTop: 10
        },
        description: {
            width: '95%'
        },
        rarity: {
            width: '5%',
            height: '100%'
        }
    }));

    const getCardRarityBG = (rarity) => {
        switch(rarity) {
            case 'C':
                return css.palette.common(1)
            case 'R':
                return css.palette.rare(1)
            case 'E':
                return css.palette.epic(1)
            case 'L':
                return css.palette.legendary(1)
        }
    }

    const classes = useStyles();

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState()
    const [dialogUrl, setDialogUrl] = React.useState()

    const onClose = () => {
        setDialogIsOpen(false)
    }
    const openTransfermarktDialog = (url, title) => {
        setDialogTitle(title)
        setDialogUrl(url)
        setDialogIsOpen(true)
    }

    return(
        <Card className={classes.root}>
            <CardMedia
                className={classes.cover}
                image={"https://cdn.fantalegends.com/images/cards_little/"+card.album_id+"/0/"+card.card_id+".webp"}
            />
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Grid className={classes.description}>
                        <Typography variant="subtitle1" style={{marginTop: 10, fontSize: '0.9rem'}}>
                            {card.short_name} [{card.soccer_player_id}]
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary" style={{marginTop: 5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {
                                card.ruoli ?
                                    card.ruoli.map((ruolo) => {
                                        return (<span style={{padding: 6, background: css.palette.fl4_alpha(.2), borderRadius: 10, marginRight: 5}}>{ruolo.ruolo}</span>)
                                    })
                                    : undefined
                            }
                        </Typography>
                        <Grid className={classes.tmktButton} >
                            <Button
                                variant="outlined"
                                onClick={() =>  openTransfermarktDialog("https://www.transfermarkt.it/schnellsuche/ergebnis/schnellsuche?query="+card.match_name, card.match_name+' - '+card.team_name)}
                                style={{height: 30, minWidth: 25}}
                            >
                                <Icon path={mdiAlphaT} style={{height: 30, width: 30}} />
                                <Icon path={mdiCached} style={{height: 20, width: 20}} />
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={()=> window.open("https://www.google.com/search?q="+card.match_name, "_blank")}
                                style={{height: 30, minWidth: 25, marginLeft: 10}}
                            >
                                <Icon path={mdiGoogle} style={{height: 20, width: 20}} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={classes.rarity} style={{background: getCardRarityBG(card.rarity)}} />
                </CardContent>
                <TransfermarktDialog open={dialogIsOpen} url={dialogUrl} onClose={onClose} title={dialogTitle} />
            </div>
        </Card>
    )
}

export const SPCardModAvailability = ({card, setIsActive, setIsReleased}) => {
    const theme = useTheme()
    const matches_down_md = useMediaQuery(theme.breakpoints.down('md'))
    const match_sm_down = useMediaQuery(theme.breakpoints.down('sm'))

    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
            width: matches_down_md ? '95%' : '24%',
            marginTop: 10,
            marginRight: 10,
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            '&.MuiCardContent-root:last-child': {
                paddingBottom: 0
            },
            minHeight: 150
        },
        cover: {
            width: 130,
            height: 160
        },
        tmktButton: {
            display: 'flex',
            marginTop: 10
        },
        description: {
            width: '95%'
        },
        rarity: {
            width: '5%',
            height: '100%'
        }
    }));

    const getCardRarityBG = (rarity) => {
        switch(rarity) {
            case 'C':
                return css.palette.common(1)
            case 'R':
                return css.palette.rare(1)
            case 'E':
                return css.palette.epic(1)
            case 'L':
                return css.palette.legendary(1)
        }
    }

    const classes = useStyles();

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState()
    const [dialogUrl, setDialogUrl] = React.useState()

    const onClose = () => {
        setDialogIsOpen(false)
    }
    const openTransfermarktDialog = (url, title) => {
        setDialogTitle(title)
        setDialogUrl(url)
        setDialogIsOpen(true)
    }
    return(
        <Card className={classes.root}>
            <CardMedia
                className={classes.cover}
                image={"https://cdn.fantalegends.com/images/cards_little/"+card.album_id+"/0/"+card.card_id+".webp"}
            />
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Grid className={classes.description}>
                        <Grid style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography variant="subtitle2" color="textSecondary" style={{marginTop: 5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginRight: 5}}>
                                {
                                    card.ruoli ?
                                        card.ruoli.map((ruolo) => {
                                            return (<span style={{padding: 6, background: css.palette.fl4_alpha(.2), borderRadius: 10, marginRight: 5}}>{ruolo.ruolo}</span>)
                                        })
                                        : undefined
                                }
                            </Typography>
                            <Typography variant="subtitle1" style={{marginTop: 10, fontSize: '0.9rem'}}>
                                {card.short_name}
                            </Typography>
                        </Grid>
                        <Grid className={classes.tmktButton} >
                            <Button
                                variant="outlined"
                                onClick={() =>  openTransfermarktDialog("https://www.transfermarkt.it/schnellsuche/ergebnis/schnellsuche?query="+card.match_name, card.match_name+' - '+card.team_name)}
                                style={{height: 30, minWidth: 25}}
                            >
                                <Icon path={mdiAlphaT} style={{height: 30, width: 30}} />
                                <Icon path={mdiCached} style={{height: 20, width: 20}} />
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={()=> window.open("https://www.google.com/search?q="+card.match_name, "_blank")}
                                style={{height: 30, minWidth: 25, marginLeft: 10}}
                            >
                                <Icon path={mdiGoogle} style={{height: 20, width: 20}} />
                            </Button>
                        </Grid>
                        <Grid style={{marginTop:10, display: 'flex', flexDirection: 'row'}}>
                            <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center'}}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={card.is_active} onChange={(e) => setIsActive(e, card.card_id, card.short_name)} name="sbustabile" />}
                                        label={"SBUSTABILE"}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={card.is_released} onChange={(e) => setIsReleased(e, card.card_id, card.short_name)} name="esiste" />}
                                        label={"ESISTE"}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid className={classes.rarity} style={{background: getCardRarityBG(card.rarity)}} />
                </CardContent>
                <TransfermarktDialog open={dialogIsOpen} url={dialogUrl} onClose={onClose} title={dialogTitle} />
            </div>
        </Card>
    )
}

export const SPCardCheckRarityPos = ({card, onSave, onNext, setRarity, addOrRemovePosition, ruoli, setIsActive, setIsReleased, setShirtNumber}) => {
    const useStyles = makeStyles(() => ({
        root: {
            width: '100%',
            maxWidth: 500
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        buttonNext: {
            marginLeft: 'auto',
            color: css.palette.text(.8),
            background: css.palette.fl3_alpha(.5)
        },
        buttonSave: {
            color: css.palette.text(.8),
            background: css.palette.fl3_alpha(.5)
        }
    }));

    const MyCardHeader = withStyles({
        title: {
            fontSize: '2rem',
            paddingBottom: 0
        },
        subheader: {
            fontSize: '1.5rem'
        }
    })(CardHeader)

    const classes = useStyles()

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState()
    const [dialogUrl, setDialogUrl] = React.useState()

    const onClose = () => {
        setDialogIsOpen(false)
    }
    const openTransfermarktDialog = (url, title) => {
        setDialogTitle(title)
        setDialogUrl(url)
        setDialogIsOpen(true)
    }

    return(
        <Card className={classes.root}>
            <MyCardHeader
                avatar={
                    <Avatar style={{height: 100, width: 100}}>
                        <img src={card.image_url} alt={card.short_name} height={100} width={100} />
                    </Avatar>
                }
                title={card.match_name}
                subheader={card.team_name}
            />
            <CardContent>
                <Grid style={{marginBottom: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button
                        variant="outlined"
                        onClick={() =>  openTransfermarktDialog("https://www.transfermarkt.it/schnellsuche/ergebnis/schnellsuche?query="+card.short_name, card.short_name+' - '+card.team_name)} >
                        TRANSFERMARKT <Icon path={mdiCached} style={{height: 20, width: 20, marginLeft: 10}} />
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={()=> window.open("https://www.google.com/search?q="+card.match_name, "_blank")}
                        style={{height: 30, minWidth: 25, marginLeft: 10}}
                    >
                        <Icon path={mdiGoogle} style={{height: 20, width: 20}} />
                    </Button>
                </Grid>
                <Grid>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                        RARITA'
                    </Typography>
                </Grid>
                <Grid style={{marginTop: 10}}>
                    <ButtonGroup style={{borderColor: 'white'}}>
                        <Button
                            style={{
                                background: css.palette.legendary(card.rarity === 'L' ? '1' : '.05'),
                                border: card.rarity==='L' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity('L')}
                        >
                            LEGG.
                        </Button>
                        <Button
                            style={{
                                background: css.palette.epic(card.rarity === 'E' ? '1' : '.05'),
                                border: card.rarity==='E' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity('E')}
                        >
                            EPICO
                        </Button>
                        <Button
                            style={{
                                background: css.palette.rare(card.rarity === 'R' ? '1' : '.05'),
                                border: card.rarity==='R' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity('R')}
                        >
                            RARO
                        </Button>
                        <Button
                            style={{
                                background: css.palette.common(card.rarity === 'C' ? '1' : '.05'),
                                border: card.rarity==='C' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity('C')}
                        >
                            COMUNE
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid style={{marginTop: 20}}>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                        NUMERO DI MAGLIA
                    </Typography>
                </Grid>
                <Grid style={{marginTop: 10}}>
                    <C.INPUTS.Number label="Numero" value={card.shirt_number} onChange={(e) => setShirtNumber(e)} width="30%" />
                </Grid>
                <Grid style={{marginTop: 20}}>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                        RUOLI
                    </Typography>
                </Grid>
                <Grid style={{marginTop:10, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row'}}>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center'}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '1')[0].active} onChange={(e) => addOrRemovePosition(e)} name="1" />}
                                label={"POR ("+ ruoli.filter(i => i.id === '1')[0].priority+")"}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid style={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row'}}>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '2')[0].active} onChange={(e) => addOrRemovePosition(e)} name="2" />}
                                label={"TD ("+ ruoli.filter(i => i.id === '2')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '5')[0].active} onChange={(e) => addOrRemovePosition(e)} name="5" />}
                                label={"ED ("+ ruoli.filter(i => i.id === '5')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '8')[0].active} onChange={(e) => addOrRemovePosition(e)} name="8" />}
                                label={"AD ("+ ruoli.filter(i => i.id === '8')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '3')[0].active} onChange={(e) => addOrRemovePosition(e)} name="3" />}
                                label={"DC ("+ ruoli.filter(i => i.id === '3')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '6')[0].active} onChange={(e) => addOrRemovePosition(e)} name="6" />}
                                label={"CC ("+ ruoli.filter(i => i.id === '6')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '9')[0].active} onChange={(e) => addOrRemovePosition(e)} name="9" />}
                                label={"TR ("+ ruoli.filter(i => i.id === '9')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center', marginTop: 15}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '4')[0].active} onChange={(e) => addOrRemovePosition(e)} name="4" />}
                                label={"TS ("+ ruoli.filter(i => i.id === '4')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '7')[0].active} onChange={(e) => addOrRemovePosition(e)} name="7" />}
                                label={"ES ("+ ruoli.filter(i => i.id === '7')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '10')[0].active} onChange={(e) => addOrRemovePosition(e)} name="10" />}
                                label={"AS ("+ ruoli.filter(i => i.id === '10')[0].priority+")"}
                                style={{marginTop: 10}}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid style={{marginTop:15, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row'}}>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center'}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={ruoli.filter(i => i.id === '11')[0].active} onChange={(e) => addOrRemovePosition(e)} name="11" />}
                                label={"ATT ("+ ruoli.filter(i => i.id === '11')[0].priority+")"}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid style={{marginTop: 20}}>
                    <Typography variant="subtitle1" color="textSecondary" component="p">
                        DISPONIBILITA'
                    </Typography>
                </Grid>
                <Grid style={{marginTop:10, display: 'flex', flexDirection: 'row'}}>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center'}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={card.is_active} onChange={(e) => setIsActive(e)} name="sbustabile" />}
                                label={"Sbustabile e schierabile"}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid style={{marginTop:10, display: 'flex', flexDirection: 'row'}}>
                    <FormControl component="fieldset" style={{display: 'flex', justifyContent: 'center'}}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={card.is_released} onChange={(e) => setIsReleased(e)} name="esiste" />}
                                label={"ESISTE (in grigio nella collezione)"}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
                <Button
                    variant="outlined"
                    className={classes.buttonSave}
                    startIcon={<Icon path={mdiContentSaveOutline} style={{height: 20, width: 20}} />}
                    onClick={onSave}
                >
                    SALVA
                </Button>
                <Button
                    variant="outlined"
                    className={classes.buttonNext}
                    endIcon={<Icon path={mdiPageNextOutline} style={{height: 20, width: 20}} />}
                    onClick={onNext}
                >
                    NEXT
                </Button>
            </CardActions>
            <TransfermarktDialog open={dialogIsOpen} url={dialogUrl} onClose={onClose} title={dialogTitle} />
        </Card>
    )
}

export const SPCardCheckRarityBulk = React.memo(({card, setRarity}) => {
    const theme = useTheme()
    const match_sm_down = useMediaQuery(theme.breakpoints.down('sm'))
    const match_md = useMediaQuery(theme.breakpoints.only('md'))

    const useStyles = makeStyles(() => ({
        row: {
            width: match_sm_down ? '100%' : match_md ? '90%' : '65%',
            padding: match_sm_down ? 5 : 15,
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center'
        },
        avatar: {
            display: match_sm_down ? 'none':'flex',
            height: match_sm_down ? 63 : 63,
            width: match_sm_down ? 50 : 50,
            marginRight: match_sm_down ? 5 : 10,
        },
        team: {
            display: 'flex',
            height: match_sm_down ? 30 : 50,
            width: match_sm_down ? 30 : 50,
            marginRight: match_sm_down ? 5 : 20,
        },
        name: {
            fontSize: match_sm_down ? '0.7rem' : '1rem',
            marginRight: match_sm_down ? 5 : 15,
            alignItems: 'center'
        },
        rarity: {
            display: 'flex',
            borderColor: 'rgba(255,255,255,1)',
            flexGrow: 1,
            justifyContent: 'flex-end'
        },
        typoGroup: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 5,
            width: '100%'
        },
        tmktButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: match_sm_down ? 5 : 30,
        }
    }));
    const StyledBadge = withStyles(() => ({
        badge: {
            background: css.gradients.flGradient_deg_alpha(45,1)
        }
    }))(Badge)

    const classes = useStyles()

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState()
    const [dialogUrl, setDialogUrl] = React.useState()

    const onClose = () => {
        setDialogIsOpen(false)
    }
    const openTransfermarktDialog = (url, title) => {
        setDialogTitle(title)
        setDialogUrl(url)
        setDialogIsOpen(true)
    }

    return(
        <Paper className={classes.row} >
            <img src={"https://cdn.fantalegends.com/images/cards_little/"+card.album_id+"/0/"+card.card_id+".webp"} alt={card.short_name} className={classes.avatar} />
            <Grid style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <StyledBadge badgeContent={<FontAwesomeIcon icon={faExchangeAlt} style={{color: '#111'}}/>} className={classes.team} invisible={+card.team_changed===0} >
                    <img src={card.team_img} alt={card.team_name} />
                </StyledBadge>
                <Grid className={classes.tmktButton} >
                    <Button
                        variant="outlined"
                        onClick={() =>  openTransfermarktDialog("https://www.transfermarkt.it/schnellsuche/ergebnis/schnellsuche?query="+card.match_name, card.match_name+' - '+card.team_name)}
                        style={{height: 30, minWidth: 25}}
                    >
                        <Icon path={mdiAlphaT} style={{height: 30, width: 30}} />
                        <Icon path={mdiCached} style={{height: 20, width: 20}} />
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={()=> window.open("https://www.google.com/search?q="+card.match_name, "_blank")}
                        style={{height: 30, minWidth: 25, marginLeft: 10}}
                    >
                        <Icon path={mdiGoogle} style={{height: 20, width: 20}} />
                    </Button>
                </Grid>
                <Grid className={classes.typoGroup}>
                    <Typography className={classes.name}>{card.short_name}<br/>{card.team_name} ({card.fl_position_abbreviation})</Typography>
                    <Typography className={classes.name}>{card.points}</Typography>
                    <Typography className={classes.name}>{card.pres}</Typography>
                </Grid>
                <Grid style={{display: 'flex', flexGrow: 1}}>
                    <ButtonGroup className={classes.rarity} size={match_sm_down ? 'small' : 'medium'} >
                        <Button
                            style={{
                                background: css.palette.legendary(card.rarity === 'L' ? '1' : '.05'),
                                border: card.rarity==='L' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity(card.card_id, 'L')}
                        >
                            LEGG.
                        </Button>
                        <Button
                            style={{
                                background: css.palette.epic(card.rarity === 'E' ? '1' : '.05'),
                                border: card.rarity==='E' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity(card.card_id, 'E')}
                        >
                            EPICO
                        </Button>
                        <Button
                            style={{
                                background: css.palette.rare(card.rarity === 'R' ? '1' : '.05'),
                                border: card.rarity==='R' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity(card.card_id, 'R')}
                        >
                            RARO
                        </Button>
                        <Button
                            style={{
                                background: css.palette.common(card.rarity === 'C' ? '1' : '.05'),
                                border: card.rarity==='C' ? '1px solid black' : '1px solid rgba(0,0,0,.2)'
                            }}
                            onClick={() => setRarity(card.card_id, 'C')}
                        >
                            COMUNE
                        </Button>
                    </ButtonGroup>
                </Grid>
                <TransfermarktDialog open={dialogIsOpen} url={dialogUrl} onClose={onClose} title={dialogTitle} />
            </Grid>
        </Paper>
    )
})

export const SPCardTransferCheckFrom = React.memo(({card, setValidOrInvalid}) => {
    const theme = useTheme()
    const match_sm_down = useMediaQuery(theme.breakpoints.down('sm'))
    const match_md = useMediaQuery(theme.breakpoints.only('md'))

    const useStyles = makeStyles(() => ({
        row: {
            width: match_sm_down ? '100%' : match_md ? '90%' : '65%',
            padding: match_sm_down ? 5 : 15,
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center'
        },
        avatar: {
            display: match_sm_down ? 'none':'flex',
            height: match_sm_down ? 30 : 50,
            width: match_sm_down ? 30 : 50,
            marginRight: match_sm_down ? 5 : 10,
        },
        team: {
            display: 'flex',
            height: match_sm_down ? 30 : 50,
            width: match_sm_down ? 30 : 50,
            marginRight: match_sm_down ? 5 : 20,
        },
        name: {
            display: 'flex',
            fontSize: match_sm_down ? '0.7rem' : '1rem',
            marginRight: match_sm_down ? 5 : 15,
            flex: 1,
            alignItems: 'center'
        },
        rarity: {
            display: 'flex',
            borderColor: 'rgba(255,255,255,1)',
            flexGrow: 1,
            justifyContent: 'flex-end'
        },
        typoGroup: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },
        tmktButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: match_sm_down ? 5 : 30,
        }
    }));
    const StyledBadge = withStyles(() => ({
        badge: {
            background: css.gradients.flGradient_deg_alpha(45,1)
        }
    }))(Badge)

    const classes = useStyles()

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState()
    const [dialogUrl, setDialogUrl] = React.useState()

    const onClose = () => {
        setDialogIsOpen(false)
    }
    const openTransfermarktDialog = (url, title) => {
        setDialogTitle(title)
        setDialogUrl(url)
        setDialogIsOpen(true)
    }

    return(
        <Paper className={classes.row} >
            <Grid style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <Grid className={classes.tmktButton} >
                    <Button
                        variant="outlined"
                        onClick={() =>  openTransfermarktDialog("https://www.transfermarkt.it/schnellsuche/ergebnis/schnellsuche?query="+card.from_match_name, card.from_match_name+' - '+card.from_team_name)}
                        style={{height: 30, minWidth: 25}}
                    >
                        <Icon path={mdiAlphaT} style={{height: 30, width: 30}} />
                        <Icon path={mdiCached} style={{height: 20, width: 20}} />
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={()=> window.open("https://www.google.com/search?q="+card.from_match_name, "_blank")}
                        style={{height: 30, minWidth: 25, marginLeft: 10}}
                    >
                        <Icon path={mdiGoogle} style={{height: 20, width: 20}} />
                    </Button>
                </Grid>
                <Grid className={classes.typoGroup}>
                    <Typography className={classes.name}>{card.from_match_name}<br/>{card.from_team_name}</Typography>
                </Grid>
                <Grid style={{display: 'flex', flexGrow: 1}}>
                    <Button variant="contained" style={{width: 150}} onClick={() => setValidOrInvalid(1, card.id_transfer)}>OK</Button>
                    <Button variant="contained" color="secondary" style={{width: 150, marginLeft: 10}} onClick={() => setValidOrInvalid(0, card.id_transfer)}>NON VALIDO</Button>
                </Grid>
                <TransfermarktDialog open={dialogIsOpen} url={dialogUrl} onClose={onClose} title={dialogTitle} />
            </Grid>
        </Paper>
    )
})

export const SPCardTransferCheckTo = React.memo(({card, setValidOrInvalid}) => {
    const theme = useTheme()
    const match_sm_down = useMediaQuery(theme.breakpoints.down('sm'))
    const match_md = useMediaQuery(theme.breakpoints.only('md'))

    const useStyles = makeStyles(() => ({
        row: {
            width: match_sm_down ? '100%' : match_md ? '90%' : '65%',
            padding: match_sm_down ? 5 : 15,
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center'
        },
        avatar: {
            display: match_sm_down ? 'none':'flex',
            height: match_sm_down ? 30 : 50,
            width: match_sm_down ? 30 : 50,
            marginRight: match_sm_down ? 5 : 10,
        },
        team: {
            display: 'flex',
            height: match_sm_down ? 30 : 50,
            width: match_sm_down ? 30 : 50,
            marginRight: match_sm_down ? 5 : 20,
        },
        name: {
            display: 'flex',
            fontSize: match_sm_down ? '0.7rem' : '1rem',
            marginRight: match_sm_down ? 5 : 15,
            flex: 1,
            alignItems: 'center'
        },
        rarity: {
            display: 'flex',
            borderColor: 'rgba(255,255,255,1)',
            flexGrow: 1,
            justifyContent: 'flex-end'
        },
        typoGroup: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },
        tmktButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: match_sm_down ? 5 : 30,
        }
    }));
    const StyledBadge = withStyles(() => ({
        badge: {
            background: css.gradients.flGradient_deg_alpha(45,1)
        }
    }))(Badge)

    const classes = useStyles()

    const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
    const [dialogTitle, setDialogTitle] = React.useState()
    const [dialogUrl, setDialogUrl] = React.useState()

    const onClose = () => {
        setDialogIsOpen(false)
    }
    const openTransfermarktDialog = (url, title) => {
        setDialogTitle(title)
        setDialogUrl(url)
        setDialogIsOpen(true)
    }

    return(
        <Paper className={classes.row} >
            <Grid style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <Grid className={classes.tmktButton} >
                    <Button
                        variant="outlined"
                        onClick={() =>  openTransfermarktDialog("https://www.transfermarkt.it/schnellsuche/ergebnis/schnellsuche?query="+card.to_match_name, card.to_match_name+' - '+card.to_team_name)}
                        style={{height: 30, minWidth: 25}}
                    >
                        <Icon path={mdiAlphaT} style={{height: 30, width: 30}} />
                        <Icon path={mdiCached} style={{height: 20, width: 20}} />
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={()=> window.open("https://www.google.com/search?q="+card.to_match_name, "_blank")}
                        style={{height: 30, minWidth: 25, marginLeft: 10}}
                    >
                        <Icon path={mdiGoogle} style={{height: 20, width: 20}} />
                    </Button>
                </Grid>
                <Grid className={classes.typoGroup}>
                    <Typography className={classes.name}>{card.to_match_name}<br/>{card.to_team_name}</Typography>
                </Grid>
                <Grid style={{display: 'flex', flexGrow: 1}}>
                    <Button variant="contained" style={{width: 150}} onClick={() => setValidOrInvalid(1, card.id_transfer)}>OK</Button>
                    <Button variant="contained" color="secondary" style={{width: 150, marginLeft: 10}} onClick={() => setValidOrInvalid(0, card.id_transfer)}>NON VALIDO</Button>
                </Grid>
                <TransfermarktDialog open={dialogIsOpen} url={dialogUrl} onClose={onClose} title={dialogTitle} />
            </Grid>
        </Paper>
    )
})
