import React from 'react';
import * as C from '../../components'

import {makeStyles} from "@material-ui/core/styles";
import CardsOverview from "./cardsOverviewPage";
import SeasonsTopPlayersPage from "./seasonsTopPlayersPage";
import NoPosOrRarityCheck from "./noPosOrRarityCheckPage";
import BulkRarityMod from "./bulkRarityMod";
import AvailabilityMod from "./availabilityMod";
import CheckTransfers from "./checkTransfers";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: {
        ...theme.mixins.toolbar,
    }
}));

export default function CardsRoutes() {
    const classes = useStyles()

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <C.ROUTES.PrivateRoute exact path="/cards/all" component={CardsOverview} />
            <C.ROUTES.PrivateRoute exact path="/cards/no_pos_or_rarity" component={NoPosOrRarityCheck} />
            <C.ROUTES.PrivateRoute exact path="/cards/bulk_rarity_mod" component={BulkRarityMod} />
            <C.ROUTES.PrivateRoute exact path="/cards/availability_mod" component={AvailabilityMod} />
            <C.ROUTES.PrivateRoute exact path="/cards/seasons_top_players" component={SeasonsTopPlayersPage} />
            <C.ROUTES.PrivateRoute exact path="/cards/check_transfers" component={CheckTransfers} />
        </main>
    );
}