import React from 'react';
import { toast } from 'react-toastify';
import css from '../styles/css'

import 'react-toastify/dist/ReactToastify.css';

export function useToast() {
    const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            background: css.palette.text(.9),
            color: css.palette.textWhite(.9)
        }
    }

    const success = (msg) => toast.success(msg, options)
    const error = (msg) => toast.error(msg, options)

    return {
        success, error
    }
}