import * as React from "react"
import {Drawer, Hidden, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ListItemText, ListItemIcon, ListItem, List, Collapse} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Icon from '@mdi/react'

// ICONS
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import { mdiCardsOutline, mdiCards, mdiAlert, mdiTrophy, mdiChartTimelineVariant, mdiAccountDetails, mdiPlaylistEdit, mdiAccountQuestionOutline, mdiCalendarMultipleCheck, mdiCalendar, mdiGraph, mdiGoogleController, mdiSourceBranch, mdiSwapHorizontalCircleOutline } from '@mdi/js';

import {useMst} from "../stores/mstStore";
import * as CONSTANTS from "../config/constants"
import {observer} from 'mobx-react'
import css from '../styles/css'
import {useHistory, useLocation} from "react-router-dom";

const MyListItem = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: css.palette.fl3_alpha(.5),
        },
        width: '90%',
        display: 'flex',
        borderRadius: 10,
        marginBottom: 5
    }
})(ListItem)
const MyNestedListItem = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: css.palette.fl3_alpha(.5),
        },
        width: '100%',
        display: 'flex',
        borderRadius: 10,
        marginBottom: 1,
        paddingLeft: '10px !important'
    }
})(ListItem)
const MyListItemIcon = withStyles({
    root: {
        minWidth: 35,
        color: 'rgba(0,0,0,.7)',
        justifyContent: 'center',
        marginRight: 5
    }
})(ListItemIcon)
const MyListItemText = withStyles({
    root: {
        '& span.MuiListItemText-primary': {
            fontSize: '0.75rem'
        }
    }
})(ListItemText)

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: CONSTANTS.DRAWER_WITH,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: CONSTANTS.DRAWER_WITH,
        borderRight: '0px'
    },
    toolbar: {
        ...theme.mixins.toolbar,
        backgroundColor: 'black',
        borderWidth: 0
    },
    toolbar_2: {
        ...theme.mixins.toolbar,
        position: 'absolute',
        backgroundColor: css.palette.fl1_alpha(.85),
        width: CONSTANTS.DRAWER_WITH,
        borderWidth: 0
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const MainDrawer = observer(({window}) => {
    const classes = useStyles()
    const container = window !== undefined ? () => window().document.body : undefined
    const store = useMst()
    const drawerRef = React.createRef()

    const DrawerMenu = observer(() => {
        const classes = useStyles();
        const [openStats, setOpenStats] = React.useState(false);
        const [openCards, setOpenCards] = React.useState(false)
        const [openEvents, setOpenEvents] = React.useState(false)
        const location = useLocation()
        const navigation = useHistory()

        const handleClickStats = () => {
            setOpenStats(!openStats);
            setOpenCards(false)
            setOpenEvents(false)
        };
        const handleClickDataManager = () => {
            setOpenCards(!openCards)
            setOpenStats(false)
            setOpenEvents(false)
        }
        const handleClickEvents = () => {
            setOpenEvents(!openEvents)
            setOpenCards(false)
            setOpenStats(false)
        }
        const handleMenuClick = (dest) => {
            if(dest!==location.pathname) {
                navigation.push(dest)
            }
        }

        React.useEffect(() => {
           if(location.pathname.startsWith('/stats')) {
               setOpenStats(true)
           } else {
               setOpenStats(false)
           }
           if(location.pathname.startsWith('/cards')) {
               setOpenCards(true)
           } else {
               setOpenCards(false)
           }
        },[location.pathname])

        return(
            <div style={{borderWidth: 0}}>
                <div className={classes.toolbar_2} />
                <div className={classes.toolbar} />
                <List component="nav" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <MyListItem button selected={location.pathname ==='/'} onClick={() => handleMenuClick('/')}>
                        <MyListItemIcon><DashboardRoundedIcon /></MyListItemIcon>
                        <MyListItemText primary="Dashboard" />
                    </MyListItem>
                    <MyListItem button onClick={handleClickDataManager}>
                        <MyListItemIcon><Icon path={mdiCardsOutline} style={{height: 25, width: 25}} /></MyListItemIcon>
                        <MyListItemText primary="Cards" />
                        {openCards ? <ExpandLess /> : <ExpandMore />}
                    </MyListItem>
                    <Collapse in={openCards} timeout="auto" unmountOnExit style={{width: '80%'}}>
                        <List component="div" disablePadding>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/cards/all'} onClick={() => handleMenuClick('/cards/all')}>
                                <MyListItemIcon><Icon path={mdiCards} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Tutte le Carte"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/cards/no_pos_or_rarity'} onClick={() => handleMenuClick('/cards/no_pos_or_rarity')}>
                                <MyListItemIcon><Icon path={mdiAlert} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Senza Ruolo o Rarità"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/cards/bulk_rarity_mod'} onClick={() => handleMenuClick('/cards/bulk_rarity_mod')}>
                                <MyListItemIcon><Icon path={mdiPlaylistEdit} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Modifica Rarità (Bulk)"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/cards/availability_mod'} onClick={() => handleMenuClick('/cards/availability_mod')}>
                                <MyListItemIcon><Icon path={mdiAccountQuestionOutline} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Modifica Disponibilità"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/cards/seasons_top_players'} onClick={() => handleMenuClick('/cards/seasons_top_players')}>
                                <MyListItemIcon><Icon path={mdiTrophy} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Migliori Giocatori (Season)"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/cards/check_transfers'} onClick={() => handleMenuClick('/cards/check_transfers')}>
                                <MyListItemIcon><Icon path={mdiSwapHorizontalCircleOutline} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Check Transfers"} />
                            </MyNestedListItem>
                        </List>
                    </Collapse>
                    <MyListItem button onClick={handleClickEvents}>
                        <MyListItemIcon><Icon path={mdiCalendarMultipleCheck} style={{height: 25, width: 25}} /></MyListItemIcon>
                        <MyListItemText primary="Eventi" />
                        {openEvents ? <ExpandLess /> : <ExpandMore />}
                    </MyListItem>
                    <Collapse in={openEvents} timeout="auto" unmountOnExit style={{width: '80%'}}>
                        <List component="div" disablePadding>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/events/events'} onClick={() => handleMenuClick('/events/events')}>
                                <MyListItemIcon><Icon path={mdiCalendar} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Eventi"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/events/stages'} onClick={() => handleMenuClick('/events/stages')}>
                                <MyListItemIcon><Icon path={mdiSourceBranch} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Fasi"} />
                            </MyNestedListItem>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/events/rounds'} onClick={() => handleMenuClick('/events/rounds')}>
                                <MyListItemIcon><Icon path={mdiGoogleController} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Giornate"} />
                            </MyNestedListItem>
                        </List>
                    </Collapse>
                    <MyListItem button onClick={handleClickStats}>
                        <MyListItemIcon><Icon path={mdiChartTimelineVariant} style={{height: 25, width: 25}} /></MyListItemIcon>
                        <MyListItemText primary="Stats" />
                        {openStats ? <ExpandLess /> : <ExpandMore />}
                    </MyListItem>
                    <Collapse in={openStats} timeout="auto" unmountOnExit style={{width: '80%'}}>
                        <List component="div" disablePadding>
                            <MyNestedListItem button className={classes.nested} selected={location.pathname === '/stats/match_viewer'} onClick={() => handleMenuClick('/stats/match_viewer')}>
                                <MyListItemIcon><Icon path={mdiAccountDetails} style={{height: 20, width: 20}} /></MyListItemIcon>
                                <MyListItemText primary={"Dettaglio Punti per Partita"} />
                            </MyNestedListItem>
                        </List>
                    </Collapse>
                </List>
            </div>
        )
    })

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <Drawer
                    ref={drawerRef}
                    container={container}
                    variant="temporary"
                    anchor={'left'}
                    open={store.navigation.isDrawerMobileOpen}
                    onClose={store.navigation.handleDrawerMobileOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <DrawerMenu />
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <DrawerMenu />
                </Drawer>
            </Hidden>
        </nav>
    )
})