import * as React from 'react'
import {
    FormControl,
    InputBase,
    InputLabel,
    makeStyles,
    NativeSelect,
    withStyles,
    useMediaQuery,
    useTheme,
    FormHelperText, Select, MenuItem, Typography
} from "@material-ui/core";
import API from '../api'
import {useField} from "formik";
import {at} from "lodash";
import moment from 'moment'

import css from 'styles/css'

const useGlobalStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1)
    },
}));
const MyInputBase = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        borderBottom: '1px solid '+css.palette.fl4,
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            // borderColor: '#80bdff',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export const YesOrNo =({value, onChange, pt_sm, width, label, pt}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor={label.toLowerCase()}>{label}</InputLabel>
            <NativeSelect
                id={label.toLowerCase()}
                value={value}
                onChange={onChange}
                label={label}
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                <option selected={value===1} key={1} value={1}>SI</option>
                <option selected={value===0} key={2} value={0}>NO</option>
            </NativeSelect>
        </FormControl>
    )
}

export const ActiveSeasonSelector = React.memo(({value, onChange, width, pt_sm}) => {
    const [isReady, setIsReady] = React.useState(false)
    const [seasons, setSeasons] = React.useState({})
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadSeasons(){
            const seasons = await API.SEASONS.getActiveSeaons()
            await setSeasons(seasons.data)
        })().then(() => setIsReady(true))
    },[])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="stagione">Stagione</InputLabel>
            <NativeSelect
                id="stagione"
                value={value}
                onChange={onChange}
                label="Season"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    isReady ?
                        seasons.map((i) => {
                            return(
                                <option selected={i.season_id===value} key={i.season_id} value={i.season_id}>{i.season_year}</option>
                            )
                        })
                        : <option aria-label="None" value="">Loading...</option>
                }
            </NativeSelect>
        </FormControl>
    )
})

export const Currencies = React.memo(({value, onChange, pt, pt_sm, width}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    const currencies = [
        {
            id: 1,
            name: 'Monete'
        },
        {
            id: 2,
            name: 'Gemme'
        }
    ]

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="position">Valuta</InputLabel>
            <NativeSelect
                id="position"
                value={value}
                onChange={onChange}
                label="Position"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    currencies.map((i) => <option key={i.id} value={i.id}>{i.name}</option>)
                }
            </NativeSelect>
        </FormControl>
    )
})

export const Prizes = React.memo(({value, onChange, pt, pt_sm, width}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    const prizes = [
        {
            id: 1,
            type: 'currency',
            name: 'Monete'
        },
        {
            id: 2,
            type: 'currency',
            name: 'Gemme'
        },
        {
            id: 3,
            type: 'booster',
            name: 'Serie A'
        }
    ]

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="position">Premio</InputLabel>
            <NativeSelect
                id="position"
                value={value}
                onChange={onChange}
                label="Position"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    prizes.map((i) => <option key={i.id} value={i.id}>{i.name}</option>)
                }
            </NativeSelect>
        </FormControl>
    )
})

export const Events = React.memo(({value, onChange, pt, pt_sm, width}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    // STATE
    const [events, setEvents] = React.useState()
    const [eventsLoaded, setEventsLoaded] = React.useState(false)

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function getAllActiveEvents() {
            const events = await API.EVENTS.getActiveEvents(true)
            await setEvents(events.data)
            await setEventsLoaded(true)
        })()
    },[])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="events">Evento</InputLabel>
            <NativeSelect
                id="events"
                value={value}
                onChange={onChange}
                label="events"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    eventsLoaded ?
                    events.map((i) => <option
                        key={i.event_id}
                        value={i.event_id}>
                        {i.event_name}, {i.event_format_name} - START: {moment(i.event_start).format('DD MMM, HH:mm').toString()} - FASI: [ {i.fasi_create} / {i.event_n_stages} ]
                    </option>)
                        : undefined
                }
            </NativeSelect>
        </FormControl>
    )
})

export const EventFormats = React.memo(({value, onChange, pt, pt_sm, width}) => {
    const [formats, setFormats] = React.useState()
    const [formatsLoaded, setFormatsLoaded] = React.useState(false)

    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadEventFormats() {
            const formats = await API.EVENTS.getEventFormats()
            await setFormats(formats.data)
            await setFormatsLoaded(true)
        })()
    },[])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="position">Formato</InputLabel>
            <NativeSelect
                id="formato"
                value={value}
                onChange={onChange}
                label="formato"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    formatsLoaded ?
                        formats.map((i) => <option key={i.event_format_id} value={i.event_format_id}>{i.event_format_name}</option>)
                        : undefined
                }
            </NativeSelect>
        </FormControl>
    )
})

export const Stages = React.memo(({value, onChange, pt, pt_sm, width, event}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    // STATE
    const [stages, setStages] = React.useState()
    const [stagesLoaded, setStagesLoaded] = React.useState(false)

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function getStages() {
            const stages = await API.EVENTS.getStagesByEventID(event, true)
            await setStages(stages.data)
            await setStagesLoaded(true)
        })()
    },[event])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="events">Fase</InputLabel>
            <NativeSelect
                id="events"
                value={value}
                onChange={onChange}
                label="events"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    stagesLoaded ?
                        stages.map((i) => <option
                            key={i.stage_id}
                            value={i.stage_id}>
                            {i.stage_number} - {i.stage_name}, {i.stage_format_name} - START: {moment(i.stage_start).format('DD MMM, HH:mm').toString()} - GIORNATE: [ {i.round_creati} / {i.stage_n_rounds} ]
                        </option>)
                        : undefined
                }
            </NativeSelect>
        </FormControl>
    )
})

export const StageFormats = React.memo(({value, onChange, pt, pt_sm, width}) => {
    const [formats, setFormats] = React.useState()
    const [formatsLoaded, setFormatsLoaded] = React.useState(false)

    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadStageFormats() {
            const formats = await API.EVENTS.getStageFormats()
            await setFormats(formats.data)
            await setFormatsLoaded(true)
        })()
    },[])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="formato">Formato</InputLabel>
            <NativeSelect
                id="formato"
                value={value}
                onChange={onChange}
                label="formato"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    formatsLoaded ?
                        formats.map((i) => <option key={i.stage_format_id} value={i.stage_format_id}>{i.stage_format_name}</option>)
                        : undefined
                }
            </NativeSelect>
        </FormControl>
    )
})

export const RoundFormats = React.memo(({value, onChange, pt, pt_sm, width}) => {
    const [formats, setFormats] = React.useState()
    const [formatsLoaded, setFormatsLoaded] = React.useState(false)

    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : (pt || 0)
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadRoundFormats() {
            const formats = await API.EVENTS.getRoundFormats()
            await setFormats(formats.data)
            await setFormatsLoaded(true)
        })()
    },[])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="formato">Formato</InputLabel>
            <NativeSelect
                id="formato"
                value={value}
                onChange={onChange}
                label="formato"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    formatsLoaded ?
                        formats.map((i) => <option key={i.round_format_id} value={i.round_format_id}>{i.round_format_name}</option>)
                        : undefined
                }
            </NativeSelect>
        </FormControl>
    )
})

export const GroupedPositionSelector = React.memo(({value, onChange, pt_sm, width}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="position">Ruolo</InputLabel>
            <NativeSelect
                id="position"
                value={value}
                onChange={onChange}
                label="Position"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                <option selected={value===1} key={1} value={1}>Portieri</option>
                <option selected={value===2} key={2} value={2}>Terzini</option>
                <option selected={value===3} key={3} value={3}>Dif. Centrali</option>
                <option selected={value===5} key={5} value={5}>Esterni</option>
                <option selected={value===6} key={6} value={6}>Cent. Centrali</option>
                <option selected={value===8} key={8} value={8}>Ali</option>
                <option selected={value===9} key={9} value={9}>Trequartisti</option>
                <option selected={value===11} key={11} value={11}>Punte</option>
            </NativeSelect>
        </FormControl>
    )
})

export const StageSelector = React.memo(({value, onChange, pt_sm, width, tournament}) => {
    const [isReady, setIsReady] = React.useState(false)
    const [stages, setStages] = React.useState()
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadRounds() {
            if(tournament!==0) {
                const rounds = await API.TOURNAMENTS.getStagesByTournament(tournament)
                await setStages(rounds.data)
            }
        })().then(() => setIsReady(true))
    },[tournament])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="tournament">Fase</InputLabel>
            <NativeSelect
                id="stage"
                value={value}
                onChange={onChange}
                label="stage"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    isReady ?
                        stages.map((i) => {
                            return(
                                <option selected={i.stage_id===value} key={i.stage_id} value={i.stage_id}>{i.stage_name}</option>
                            )
                        })
                        : <option aria-label="None" value="">Loading...</option>
                }
            </NativeSelect>
        </FormControl>
    )
})

export const RoundSelector = React.memo(({value, onChange, pt_sm, width, stage}) => {
    const [isReady, setIsReady] = React.useState(false)
    const [rounds, setRounds] = React.useState()
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadRounds() {
            if(stage!==0) {
                const rounds = await API.TOURNAMENTS.getTournamentRounds(stage)
                await setRounds(rounds.data)
            }
        })().then(() => setIsReady(true))
    },[stage])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="tournament">#</InputLabel>
            <NativeSelect
                id="round"
                value={value}
                onChange={onChange}
                label="round"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    isReady ?
                        rounds.map((i) => {
                            return(
                                <option selected={i.week===value} key={i.week} value={i.week}>{i.week}° Giornata</option>
                            )
                        })
                        : <option aria-label="None" value="">Loading...</option>
                }
            </NativeSelect>
        </FormControl>
    )
})

export const TeamSelector = React.memo(({value, onChange, pt_sm, width, tournament}) => {
    const [isReady, setIsReady] = React.useState(false)
    const [teams, setTeams] = React.useState()
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadTournaments() {
            if(tournament!==0) {
                const teams = await API.TEAMS.getTeamsByTournament(tournament)
                setTeams(teams.data)
            }
        })().then(() => setIsReady(true))
    },[tournament])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="team">Squadra</InputLabel>
            <NativeSelect
                id="team"
                value={value}
                onChange={onChange}
                label="team"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    isReady ?
                        teams.map((i) => {
                            return(
                                <option selected={i.team_id===value} key={i.team_id} value={JSON.stringify(i)}>{i.team_name}</option>
                            )
                        })
                        : <option aria-label="None" value="">Loading...</option>
                }
            </NativeSelect>
        </FormControl>
    )
})

export const TopSeasonPlayersAlgorithmSelector = React.memo(({value, onChange, pt_sm, width}) => {
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="algorithm">Algoritmo</InputLabel>
            <NativeSelect
                id="algorithm"
                value={value}
                onChange={onChange}
                label="algorithm"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                <option selected={value===1} key={1} value={1}>Totale Punti</option>
                <option selected={value===2} key={2} value={2}>Punti / Presenze</option>
                <option selected={value===3} key={3} value={3}>Punti / Minuti Giocati</option>
                <option selected={value===4} key={4} value={4}>Pts/Min*0.7 + Pres/38*0.3</option>
            </NativeSelect>
        </FormControl>
    )
})

export const TournamentSelector = React.memo(({value, onChange, season, width, pt_sm}) => {
    const [isReady, setIsReady] = React.useState(false)
    const [tournaments, setTournaments] = React.useState()
    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));

    const classes = useStyles()

    React.useEffect(() => {
        (async function loadTournaments() {
            if(season!==0) {
                const tournaments = await API.TOURNAMENTS.getTournamentBySeason(season)
                setTournaments(tournaments.data)
            }
        })().then(() => setIsReady(true))
    },[season])

    return(
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="tournament">Campionato</InputLabel>
            <NativeSelect
                id="tournament"
                value={value}
                onChange={onChange}
                label="tournament"
                input={<MyInputBase />}
            >
                <option aria-label="None" value="" />
                {
                    isReady ?
                        tournaments.map((i) => {
                            return(
                                <option selected={i.tournament_id===value} key={i.tournament_id} value={i.tournament_id}>{i.competition_name}</option>
                            )
                        })
                        : <option aria-label="None" value="">Loading...</option>
                }
            </NativeSelect>
        </FormControl>
    )
})


// FORMIK
export const FormikSelect = (props) => {
    const { label, data, width, pt_sm, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const [touched, error] = at(meta, 'touched', 'error');
    const isError = touched && error && true;

    const theme = useTheme()
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'))

    const globalClasses = useGlobalStyles()
    const paddingTopSM = pt_sm || 10

    const useStyles = makeStyles((theme) => ({
        formControl: {
            ...globalClasses.formControl,
            minWidth: width || '150',
            marginRight: 20,
            marginTop: matches_sm ? paddingTopSM : 'inherit'
        },
    }));
    const classes = useStyles()

    function _renderHelperText() {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    return (
        <FormControl variant="outlined" className={classes.formControl} {...rest} error={isError}>
            <InputLabel htmlFor={label.toLowerCase()}>{label}</InputLabel>
            <NativeSelect {...field} value={selectedValue ? selectedValue : ''} input={<MyInputBase />} >
                {data.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </NativeSelect>
            {_renderHelperText()}
        </FormControl>
    );
}