import React from "react"
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import css from '../styles/css'
import { at } from 'lodash';
import { useField } from 'formik';

export const LoginTextField = withStyles({
    root: {
        '& label': {
            color: css.palette.text(.7),
        },
        '& label.Mui-focused': {
            color: css.palette.text(.5),
            top: -7
        },
        '& label.MuiInputLabel-shrink': {
            color: css.palette.text(.5),
            top: -7
        },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: css.palette.fl3,
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: 0,
                borderColor: css.palette.fl1,
                backgroundColor: 'rgba(255,255,255,0.08)',
                borderRadius: 5
            },
            '&:hover fieldset': {
                borderWidth: 0,
                borderColor: css.palette.fl3,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 0,
                borderColor: css.palette.fl3,
            },
        },
    },
})(TextField);
const AppInputTextField = withStyles({
    root: {
        '& label': {
            color: css.palette.text(.7),
        },
        '& label.Mui-focused': {
            color: css.palette.text(.5),
        },
        '& label.MuiInputLabel-shrink': {
            color: css.palette.text(.5),
        },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: css.palette.fl3,
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: css.palette.fl4,
                borderRadius: 5
            },
            '&:hover fieldset': {
                borderColor: css.palette.fl4_alpha(.7),
            },
            '&.Mui-focused fieldset': {
                borderColor: css.palette.fl4_alpha(.7),
            },
        },
    },
})(TextField);

export const Text = ({value, onChange, width, label, multiline}) => {
    return(
        <AppInputTextField
            variant='outlined'
            margin="normal"
            id={label.toLowerCase()}
            label={label}
            name={label.toLowerCase()}
            value={value}
            onChange={onChange}
            multiline={multiline || false}
            rows={multiline ? 4 : 1}
            style={{width: width || '100%'}}
        />
    )
}
export const Number = ({value, onChange, width, label}) => {
    return(
        <AppInputTextField
            variant='outlined'
            margin="normal"
            id={label.toLowerCase()}
            label={label}
            name={label.toLowerCase()}
            value={value}
            onChange={onChange}
            style={{width: width || '100%'}}
            type="number"
        />
    )
}
export const Date = ({value, onChange, width, label}) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        }
    }));

    const classes = useStyles();

    return(
        <AppInputTextField
            className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
            id={label.toLowerCase()}
            label={label}
            name={label.toLowerCase()}
            value={value}
            onChange={onChange}
            style={{width: width || '100%'}}
            type="date"
            variant='outlined'
            margin="normal"
        />
    )
}
export const DateTime = ({value, onChange, width, label}) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        }
    }));

    const classes = useStyles();

    return(
        <AppInputTextField
            className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
            id={label.toLowerCase()}
            label={label}
            name={label.toLowerCase()}
            value={value}
            onChange={onChange}
            style={{width: width || '100%'}}
            type="datetime-local"
            variant='outlined'
            margin="normal"
        />
    )
}

export const FormikText = (props) => {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    return (
        <AppInputTextField
            variant='outlined'
            margin="normal"
            type="text"
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            multiline={props.multiline || false}
            rows={props.multiline ? 4 : 1}
            style={{width: props.width || '100%'}}
            {...field}
            {...rest}
        />
    );
}
export const FormikNumber = (props) => {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    return (
        <AppInputTextField
            variant='outlined'
            margin="normal"
            type="number"
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            multiline={props.multiline || false}
            rows={props.multiline ? 4 : 1}
            style={{width: props.width || '100%'}}
            {...field}
            {...rest}
        />
    );
}
export const FormikDateTime = (props) => {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    }));
    const classes = useStyles();

    function _renderHelperText() {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return error;
        }
    }

    return (
        <AppInputTextField
            InputLabelProps={{
                shrink: true,
            }}
            type="datetime-local"
            variant='outlined'
            margin="normal"
            error={meta.touched && meta.error && true}
            helperText={_renderHelperText()}
            style={{width: props.width || '100%'}}
            {...field}
            {...rest}
        />
    );
}