import * as React from 'react';
import {Button, Checkbox, Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Icon from '@mdi/react'
import { mdiCardPlus, mdiDelete, mdiContentSave } from '@mdi/js'
import { sortBy, remove } from 'lodash'
import moment from 'moment'

import * as C from "components";
import css from 'styles/css'
import API from 'api'
import {useToast} from 'hooks'
import grey from "@material-ui/core/colors/grey";

export default function RoundForm(props) {
    const [roundName, setRoundName] = React.useState(null)
    const [roundNumber, setRoundNumber] = React.useState()
    const [roundDescription, setRoundDescription] = React.useState(null)
    const [roundStart, setRoundStart] = React.useState('')
    const [roundEnd, setRoundEnd] = React.useState('')
    const [roundFormat, setRoundFormat] = React.useState(null)
    const [roundFormatName, setRoundFormatName] = React.useState(null)
    const [hasMatchmaking, setHasMatchmaking] = React.useState(null)
    const [mmStart, setMmStart] = React.useState('')
    const [mmEnd, setMmEnd] = React.useState('')
    const [prizes, setPrizes] = React.useState([])
    const [fromRank, setFromRank] = React.useState(null)
    const [toRank, setToRank] = React.useState(null)
    const [prizeQty, setPrizeQty] = React.useState(null)
    const [prizeItem, setPrizeItem] = React.useState(null)
    const [prizeItemName, setPrizeItemName] = React.useState(null)
    const [hasStandings, setHasStandings] = React.useState(null)
    const [lineupDeliveryStart, setLineupDeliveryStart] = React.useState('')
    const [lineupDeliveryEnd, setLineupDeliveryEnd] = React.useState('')

    const [season, setSeason] = React.useState()
    const [tournament, setTournament] = React.useState()
    const [stage, setStage] = React.useState()
    const [round, setRound] = React.useState()
    const [matchesLoaded, setMatchesLoaded] = React.useState(false)
    const [matches, setMatches] = React.useState()
    const [teams, setTeams] = React.useState([])
    const [allChecked, setAllChecked] = React.useState([])

    const stageID = props.stageID;

    const useStyles = makeStyles((theme) => ({
        multipleInput: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        fromToRank: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        },
        rankPrizeCat: {
            borderTop: '1px solid '+css.palette.fl4_alpha(.5),
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        matchRow: {
            display: 'flex',
            height: '2.5rem',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            marginBottom: theme.spacing(1),
            backgroundColor: grey[50]
        },
        matchItem: {
            display: 'flex',
            flex: 3,
            justifyContent: 'center'
        },
        checkItem: {
            display: 'flex',
            flex: 1
        }
    }));

    const classes = useStyles()
    const toast = useToast()

    const addRankPrizes = () => {
        if(+toRank < +fromRank) {
            alert("ERRORE: A non può essere maggiore di DA!")
        } else {
            const new_prizes = prizes.slice()
            new_prizes.push(
                {
                    from: fromRank,
                    to: toRank,
                    prizes: []
                }
            )
            setPrizes(new_prizes)
        }
    }
    const removeRankPrizes = (from) => {
        const new_prizes = prizes.filter((i) => i.from !== from)
        setPrizes(new_prizes)
    }
    const addPrizesToRank = (from) => {
        const new_prizes = prizes.slice()
        new_prizes.filter((i) => i.from === from)[0].prizes.push({
            id: prizeItem,
            name: prizeItemName,
            qty: prizeQty
        })
        setPrizes(new_prizes)
    }
    const onSave = async () => {
        const round = {
            stage: stageID,
            name: roundName,
            number: roundNumber,
            description: roundDescription,
            start: moment(roundStart).utc().format().toString(),
            end: moment(roundEnd).utc().format().toString(),
            deliveryStart: moment(lineupDeliveryStart).utc().format().toString(),
            deliveryEnd: moment(lineupDeliveryEnd).utc().format().toString(),
            format: roundFormat,
            hasStandings: hasStandings,
            hasMM: hasMatchmaking,
            mmStart: moment(mmStart).utc().format().toString(),
            mmEnd: moment(mmEnd).utc().format().toString(),
            prizes: prizes,
            teams: teams
        }
        const res = await API.EVENTS.saveNewRound(round)
        if(res.success) {
            toast.success('Fase Salvata!')
        } else {
            toast.error(res.error)
        }
    }

    const onRoundChange = async (event) => {
        await setRound(event.target.value)
        const matches = await API.MATCHES.getMatchesByRoundID(event.target.value)
        await setMatches(matches.data)
        await setMatchesLoaded(true)
    }

    const onCompleteMatchChange = async (e, match, team_1, team_2, team_1_img, team_2_img) => {
        if(e.target.checked) {
            const new_teams = teams.slice()
            new_teams.push({
                match_id: match,
                team_id: team_1,
                img: team_1_img
            })
            new_teams.push({
                match_id: match,
                team_id: team_2,
                img: team_2_img
            })
            await setTeams(new_teams)
        } else {
            const new_teams = teams.slice()
            remove(new_teams, function(obj) {return obj.match_id === match})
            await setTeams(new_teams)
        }
    }
    const onSingleTeamChange = async (e, match, team, team_img) => {
        if(e.target.checked) {
            const new_teams = teams.slice()
            new_teams.push({
                match_id: match,
                team_id: team,
                img: team_img
            })
            await setTeams(new_teams)
        } else {
            const new_teams = teams.slice()
            remove(new_teams, function(obj) {return obj.team_id === team})
            await setTeams(new_teams)
        }
    }
    const onSelectAll = async (e) => {
        if(e.target.checked) {
            const new_teams = teams.slice()
            const new_allChecked = allChecked.slice()
            matches.map(i => {
                remove(new_teams, function(n) {return n.match_id === i.match_id})
                new_teams.push({ match_id: i.match_id, team_id: i.home_team_id, img: i.home_team_img})
                new_teams.push({ match_id: i.match_id, team_id: i.away_team_id, img: i.away_team_img})
            })
            new_allChecked.push({tournament_id: tournament, round_id: round})
            await setTeams(new_teams)
            await setAllChecked(new_allChecked)
        } else {
            const new_teams = teams.slice()
            const new_allChecked = allChecked.slice()
            matches.map(i => {
                remove(new_teams, function(n){return n.match_id === i.match_id})
            })
            remove(new_allChecked, function(n){return n.tournament_id === tournament && n.round_id === round})
            await setTeams(new_teams)
            await setAllChecked(new_allChecked)
        }

    }

    const Match = ({match}) => {
        return(
            <Paper className={classes.matchRow}>
                <Grid className={classes.checkItem}>
                    <Checkbox
                        color="primary"
                        checked={teams.filter(t => t.match_id === match.match_id).length === 2}
                        onChange={(e) => onCompleteMatchChange(e, match.match_id, match.home_team_id, match.away_team_id, match.home_team_img, match.away_team_img)}
                    />
                </Grid>
                <Grid className={classes.checkItem} style={{justifyContent: 'flex-end'}}>
                    <Checkbox
                        color="primary"
                        checked={teams.filter(t => t.team_id === match.home_team_id).length === 1}
                        onChange={(e) => onSingleTeamChange(e, match.match_id, match.home_team_id, match.home_team_img)}
                    />
                </Grid>
                <Grid className={classes.matchItem}>
                    <img src={match.home_team_img} style={{width: 25, marginRight: 10}} alt={match.home_team_name} /> {match.home_team_name} - {match.away_team_name} <img src={match.away_team_img} style={{width: 25, marginLeft: 10}} alt={match.away_team_name} />
                </Grid>
                <Grid className={classes.checkItem}>
                    <Checkbox
                        color="primary"
                        checked={teams.filter(t => t.team_id === match.away_team_id).length === 1}
                        onChange={(e) => onSingleTeamChange(e, match.match_id, match.away_team_id, match.away_team_img)}
                    />
                </Grid>
            </Paper>
        )
    }

    return(
        <Grid style={{display: 'flex', justifyContent: 'space-around'}}>
            <Grid item sm={12} md={4}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <C.INPUTS.Number label="Numero" value={roundNumber} onChange={(e) => setRoundNumber(e.target.value)} width="30%" />
                        <C.INPUTS.Text label="Nome" value={roundName} onChange={(e) => setRoundName(e.target.value)} width="100%" />
                        <C.INPUTS.Text label="Descrizione" value={roundDescription} onChange={(e) => setRoundDescription(e.target.value)} width="100%" multiline rows={4}/>
                        <Grid item sm={12} className={classes.multipleInput}>
                            <C.INPUTS.DateTime label="Inizio" value={roundStart} onChange={(e) => setRoundStart(e.target.value)} width="48%" />
                            <C.INPUTS.DateTime label="Fine" value={roundEnd} onChange={(e) => setRoundEnd(e.target.value)} width="48%" />
                        </Grid>
                        <Grid item sm={12} className={classes.multipleInput}>
                            <C.INPUTS.DateTime label="Inizio Consegna Formazione" value={lineupDeliveryStart} onChange={(e) => setLineupDeliveryStart(e.target.value)} width="48%" />
                            <C.INPUTS.DateTime label="Fine Consegna Formazione" value={lineupDeliveryEnd} onChange={(e) => setLineupDeliveryEnd(e.target.value)} width="48%" />
                        </Grid>
                        <C.SELECTS.RoundFormats label="Formato" value={roundFormat} onChange={(e) => { setRoundFormat(e.target.value); setRoundFormatName(e.target.options[e.target.selectedIndex].text) }} width="100%" pt={10} />
                        <C.SELECTS.YesOrNo label="Ha la classifica?" value={hasStandings} onChange={(e) => setHasStandings(e.target.value)} width="100%" pt={20} />
                        <Grid item sm={12} className={classes.multipleInput} style={{marginTop: 5}}>
                            <C.SELECTS.YesOrNo label="MM?" value={hasMatchmaking} onChange={(e) => setHasMatchmaking(e.target.value)} width="15%" pt={24} />
                            <C.INPUTS.DateTime label="Inizio MM" value={mmStart} onChange={(e) => setMmStart(e.target.value)} width="40%" />
                            <C.INPUTS.DateTime label="Fine MM" value={mmEnd} onChange={(e) => setMmEnd(e.target.value)} width="40%" />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper style={{width: '100%', padding: 20, marginTop: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Typography variant="h6">PREMI</Typography>
                        <Grid item sm={12} className={classes.fromToRank} style={{marginTop: 5}}>
                            <C.INPUTS.Number label="Da #" value={fromRank} onChange={(e) => setFromRank(e.target.value)} width="25%" />
                            <C.INPUTS.Number label="A #" value={toRank} onChange={(e) => setToRank(e.target.value)} width="25%" />
                            <C.BUTTONS.ActionButton label="" onClick={addRankPrizes} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="10%" containerStyle={{marginTop: 24}} />
                        </Grid>
                        <Grid item sm={12} style={{marginTop:15}}>
                            {
                                sortBy(prizes, [function(o) { return +o.from; }]).map((i) => {
                                    return(
                                        <Grid item sm={12} className={classes.rankPrizeCat}>
                                            <Grid item sm={2}>
                                                {
                                                    i.from === i.to ?
                                                        <Typography>Al {i.from}°</Typography>
                                                        :
                                                        <Typography>Dal {i.from}° al {i.to}°</Typography>
                                                }
                                            </Grid>
                                            <Grid item sm={9} style={{display: 'flex', flexDirection: 'column'}}>
                                                <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
                                                    <C.INPUTS.Number label="Qty" value={prizeQty} onChange={(e) => setPrizeQty(e.target.value)} width="20%" />
                                                    <C.SELECTS.Prizes
                                                        label="Premio"
                                                        value={prizeItem}
                                                        onChange={(e) => {setPrizeItem(e.target.value); setPrizeItemName(e.target.options[e.target.selectedIndex].text)}}
                                                        pt={17}
                                                        width="40%"
                                                    />
                                                    <C.BUTTONS.ActionButton label="" onClick={() => addPrizesToRank(i.from)} IconComponent={() => <Icon path={mdiCardPlus} style={{height: 20, width: 20}} />} width="20%" />
                                                </Grid>
                                                <Grid item sm={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10}}>
                                                    {
                                                        i.prizes.map((p) => <Typography key={p.id}>{p.qty} {p.name}</Typography>)
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <C.BUTTONS.ActionButton
                                                    label=""
                                                    onClick={() => removeRankPrizes(i.from)}
                                                    IconComponent={() => <Icon path={mdiDelete} style={{height: 20, width: 20}} /> }
                                                    width="100%"
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item sm={12} md={4}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                        <Typography variant="h6">PARTITE</Typography>
                        <Grid item sm={12} className={classes.fromToRank} style={{marginTop: 5}}>
                            <C.SELECTS.ActiveSeasonSelector value={season} onChange={(e) => setSeason(e.target.value)} width="27%" />
                            <C.SELECTS.TournamentSelector value={tournament} onChange={(e) => setTournament(e.target.value)} width="27%" season={season} />
                            <C.SELECTS.StageSelector value={stage} onChange={(e) => setStage(e.target.value)} width="27%" tournament={tournament} />
                            <C.SELECTS.RoundSelector value={round} onChange={onRoundChange} width="10%" stage={stage} />
                        </Grid>
                        <Grid item sm={12} style={{marginTop: 20, marginBottom: 20, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {
                                teams.map(i => <img key={i.team_id} src={i.img} style={{width: 50, height: 50, margin: 5}} alt={i.team_id} />)
                            }
                        </Grid>
                            {
                                matchesLoaded ?
                                    <Grid style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                        <Checkbox
                                            color="primary"
                                            checked={allChecked.filter(i => i.tournament_id === tournament && i.round_id === round).length === 1}
                                            onChange={onSelectAll}
                                        />
                                        <Typography>SELEZIONA TUTTI</Typography>
                                    </Grid>
                                    : undefined
                            }
                        <Grid item sm={12} style={{marginTop:15, width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            {
                                matchesLoaded ?
                                    matches.map((m) => {
                                        return (<Match key={m.match_id} match={m} />)
                                    })
                                    : undefined
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item sm={12} md={3}>
                <Paper style={{width: '100%', padding: 20}} elevation={3}>
                    <Grid item sm={12} style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h4">RIEPILOGO</Typography>
                        <Typography variant="h6">NOME: {roundName}</Typography>
                        <Typography variant="h6">DESCRIZIONE: {roundDescription}</Typography>
                        <Typography variant="h6">Inizia il: {roundStart}</Typography>
                        <Typography variant="h6">Finisce il: {roundEnd}</Typography>
                        <Typography variant="h6">Formato: {roundFormatName}</Typography>
                        <Typography variant="h6">Ha la classifica? {+hasStandings === 1 ? 'SI' : 'NO'}</Typography>
                        <Typography variant="h4" style={{marginTop: 15}}>MATCHMAKING</Typography>
                        <Typography variant="h6">Ha il matchmaking? {+hasMatchmaking === 1 ? 'SI' : 'NO'}</Typography>
                        <Typography variant="h6">Inizio MM: {mmStart}</Typography>
                        <Typography variant="h6">Fine MM: {mmEnd}</Typography>
                    </Grid>
                    <Grid item sm={12} style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
                        <C.BUTTONS.ActionButton
                            label="SALVA"
                            onClick={onSave}
                            IconComponent={() => <Icon path={mdiContentSave} style={{height: 20, width: 20}} />}
                        />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
