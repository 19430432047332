import axios from "axios";
import {ROOT_ENDPOINT, ROOT_ADMIN_ENDPOINT, send, API_KEY} from '../config/api.config'
import {useLocalStorage} from "../hooks";

const ADMIN_ENDPOINT = ROOT_ADMIN_ENDPOINT + '/tournaments'

const User = async () => {
    const ls = useLocalStorage()
    const authToken = await ls.AUTH.getUserToken()
    const userID = ls.AUTH.getUserId()
    return {
        authToken,
        userID
    }
}

export const getTournamentBySeason = async (season) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/tournaments_by_season',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    'season': season
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const getTournamentRounds = async (stage) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/rounds',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    stage
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const getStagesByTournament = async (tournament) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/stages',
            {
                headers: {
                    'authorization': (await User()).authToken,
                    'userid': (await User()).userID,
                    tournament
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}

export const getMatchPoints = async (matchID) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/match_points_by_match_id',
            {
                headers: {
                    'apikey': API_KEY,
                    'match': matchID
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}
export const getMatchPointsDetailsBySoccerPlayerIDAndPositionID = async (card_id, position_id, matchID) => {
    try {
        return await axios.get(ADMIN_ENDPOINT + '/match_points_soccer_player_details',
            {
                headers: {
                    'apikey': API_KEY,
                    'match': matchID,
                    'card': card_id,
                    'position': position_id
                }
            }
        ).then((res) => {
            return send(res.data.data, res.data.success, res.data.error)
        })
    } catch (error) {
        return send(null, false, error.response.data.message)
    }
}